/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import DOMPurify from 'isomorphic-dompurify';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import { PageParamsContext } from '../../modules/context/PageContext';
import { internetBarIcons, statesConstant } from '../../modules/utils/constants';
import { useServiceStore, serviceStore } from '../../modules/store/serviceStore';
import { InternetBarModal } from './components/InternetBarModal';
import { connectBtnTrigger, getEnrolledUserFlag } from './utils/handler';
import { INTERNET_BAR_INITIAL_STATE, LOYALTY_DISABLED_ZONES, LOYALTY_ENROLLMENT_SOURCE } from './utils/constant';
import { getInternetBarStateSpecificClasses, StateCSS } from './internetBar.helper';
import { InternetBarProps, AEMInternetBarProps, InternetBarStateTypes } from './InternetBar.types';
import { StyledInternetBar } from './InternetBar.styles';
import { inStayMockResponse } from './__mock__/InternetBar.model';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const InternetBar: FC<InternetBarProps> = (pageProps: InternetBarProps) => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const { asPath } = useRouter();
  const serviceData = useServiceStore((state: serviceStore) => state.serviceData);

  const router = useRouter();
  const urlQueryParams: Record<string, any> = router.query;

  const {
    experienceSegment = '',
    userSegment = '',
    state = '',
    marsha = '',
    cna = '',
    sameTab = '',
    unknownFreeConnection = false as boolean,
    ppv5Enrollment = false,
    loyaltyFlip = false,
    currentStateMapping = {
      ...(pageProps?.isAuthorMode ? inStayMockResponse : (INTERNET_BAR_INITIAL_STATE as InternetBarStateTypes)),
    },
  } = preProcessorResponse || {};

  const [showIcon] = useState<boolean>(
    internetBarIcons && internetBarIcons?.[state] ? internetBarIcons?.[state]?.showIcon : false
  );
  const [internetBarDataValues, setInternetBarDataValues] = useState<Record<string, any>>({});
  const [showInternetBarModal, setShowInternetBarModal] = useState<boolean>(false);
  const [currentStateHasModalOption, setCurrentStateHasModalOption] = useState<boolean>(false);
  const [loyaltyCurrentStateMapping, setLoyaltyCurrentStateMapping] = useState({
    ...currentStateMapping,
  });
  const [isNoLoginInterfaceModal, setIsNoLoginInterfaceModal] = useState<boolean>(
    currentStateMapping?.noLoginInterfaceModal ?? false
  );
  const [modalType, setModalType] = useState('');
  const [loyaltyJoinNow, setLoyaltyJoinNow] = useState<string>('');
  const [isLoyaltyConnectNowBtnLoading, setLoyaltyConnectNowBtnLoading] = useState<boolean>(false);
  const [isDefaultConnectNowBtnLoading, setDefaultConnectNowBtnLoading] = useState<boolean>(false);

  // Get the HTML content sanitized for below HTML
  const welcomeSanrizedNode = DOMPurify.sanitize(
    (pageProps?.[currentStateMapping?.welcomeText as keyof AEMInternetBarProps] || '')
      .toString()
      .replace(/<\/?p>/g, ''),
    { ADD_ATTR: ['target'], RETURN_DOM: true }
  );
  const welcomeText = welcomeSanrizedNode.innerHTML;

  const descriptionSanrizedNode = DOMPurify.sanitize(
    (pageProps?.[currentStateMapping?.description as keyof AEMInternetBarProps] || '')
      ?.toString()
      ?.replace(/<\/?p>/g, ''),
    { ADD_ATTR: ['target'], RETURN_DOM: true }
  );
  const descriptionText = descriptionSanrizedNode.innerHTML;

  const termsAndConditionsSanrizedNode = DOMPurify.sanitize(
    (
      currentStateMapping?.termsAndConditions &&
      pageProps?.[currentStateMapping?.termsAndConditions as keyof AEMInternetBarProps]
    )?.toString() || '',
    { ADD_ATTR: ['target'], RETURN_DOM: true }
  );
  const termsAndConditionsText = termsAndConditionsSanrizedNode.innerHTML;

  const signInTextSanrizedNode = DOMPurify.sanitize(
    (
      currentStateMapping?.signInText && pageProps?.[currentStateMapping?.signInText as keyof AEMInternetBarProps]
    )?.toString() || '',
    { ADD_ATTR: ['target'], RETURN_DOM: true }
  );
  const signInText = signInTextSanrizedNode.innerHTML;

  const isWelcomeTitle: boolean = currentStateMapping && currentStateMapping?.title === 'welcomeTitle';
  const showButtonContainer =
    currentStateMapping &&
    (currentStateMapping?.joinNowButton?.joinButtonLabel ||
      currentStateMapping?.signInButton?.signInButtonLabel ||
      currentStateMapping?.connectUpgradeBtn);

  const stateCSS: StateCSS = getInternetBarStateSpecificClasses(
    userSegment,
    state,
    experienceSegment,
    unknownFreeConnection
  );

  const handleBtnClick = useCallback((btnName: string) => {
    const internetbarInitialValue = initializeInternetBarData();
    const zoneValue = internetbarInitialValue['Z'] ?? '';
    const localRequirement = internetbarInitialValue['LR'] ?? '';
    const loyaltyEnrollmentSourceCode = internetbarInitialValue['loyaltyEnrollmentSourceCode'];
    const isUserEnrolled = getEnrolledUserFlag(loyaltyEnrollmentSourceCode);

    switch (btnName) {
      case 'connect':
        if (isUserEnrolled && internetbarInitialValue?.ppv5Enrollment) {
          if (currentStateMapping?.showLoginModal) {
            setShowInternetBarModal(true);
            setModalType('LOGIN');
          } else {
            setShowInternetBarModal(false);
            setModalType('');
          }
          setDefaultConnectNowBtnLoading(currentStateMapping?.noLoginInterfaceModal ? true : false);
          connectBtnTrigger(currentStateMapping?.noLoginInterfaceModal, { ...internetbarInitialValue });
        } else {
          if (unknownFreeConnection) {
            setLoyaltyConnectNowBtnLoading(isNoLoginInterfaceModal ? true : false);
          } else {
            setDefaultConnectNowBtnLoading(isNoLoginInterfaceModal ? true : false);
          }
          setShowInternetBarModal(true);
          if (localRequirement?.toUpperCase() === 'AF_AO' && zoneValue !== 'GUEST') {
            setCurrentStateHasModalOption(!isNoLoginInterfaceModal);
            setModalType('ACCESS');
            connectBtnTrigger(isNoLoginInterfaceModal, { ...internetbarInitialValue });
          } else {
            setModalType('LOGIN');
            connectBtnTrigger(isNoLoginInterfaceModal, { ...internetbarInitialValue });
          }
        }
        break;

      case 'upgrade':
        if (currentStateMapping?.showUpgradeModal) {
          setShowInternetBarModal(true);
          currentStateMapping?.showLoginModal ? setModalType('LOGIN') : setModalType('U_PRICE');
          setCurrentStateHasModalOption(true);
        }
        break;

      case 'connectnow':
        if (zoneValue?.toUpperCase() === 'GUEST') {
          setShowInternetBarModal(true);
          setModalType('LOGIN');
          connectBtnTrigger(isNoLoginInterfaceModal, { ...internetbarInitialValue });
        } else if (zoneValue?.toUpperCase() === 'PUBLIC' || zoneValue?.toUpperCase() === 'LOBBY') {
          if (isUserEnrolled && ppv5Enrollment) {
            setShowInternetBarModal(true);
            setModalType('LOYALTY_WELCOME');
            setCurrentStateHasModalOption(true);
            setIsNoLoginInterfaceModal(false);
          } else {
            if (localRequirement && localRequirement !== 'TCTC') {
              if (localRequirement === 'AF_AO') {
                setModalType('ACCESS');
                setShowInternetBarModal(true);
                setCurrentStateHasModalOption(true);
              } else {
                setModalType('LOGIN');
                setShowInternetBarModal(true);
                connectBtnTrigger(isNoLoginInterfaceModal, { ...internetbarInitialValue });
              }
            } else {
              setLoyaltyConnectNowBtnLoading(true);
              connectBtnTrigger(true, { ...internetbarInitialValue });
            }
          }
        }

        break;

      case 'signin':
        break;

      case 'joinnow':
        break;
    }
  }, []);

  const handleCloseBtnClick = () => {
    setShowInternetBarModal(false);
    setModalType('');
  };

  const initializeInternetBarData = useCallback(() => {
    // function to validate if the URL query params are as per the AEM current state mapping
    const urlAsPerMapping = (MV: string = '', ERR: string = '') => {
      if (MV || ERR) {
        let hasModalOption = false;

        switch (MV) {
          case 'LOGIN':
          case 'LOGERR':
            hasModalOption = currentStateMapping && currentStateMapping?.showLoginModal ? true : false;
            break;

          case 'ACCESS':
          case 'ACCESSERR':
            hasModalOption = currentStateMapping && currentStateMapping?.showAccessModal ? true : false;
            break;

          case 'REQSMS':
          case 'REQSMSERR':
            hasModalOption = currentStateMapping && currentStateMapping?.showReqAccessModal ? true : false;
            break;

          case 'INT_ERR':
            hasModalOption =
              currentStateMapping &&
              (currentStateMapping?.showUpgradeGenErr ||
                currentStateMapping?.showUpgradeLRErr ||
                currentStateMapping?.showUpgradeMaxErr ||
                currentStateMapping?.showUpgradeNoCCErr ||
                currentStateMapping?.showUpgradePMSErr)
                ? true
                : false;
            break;

          case 'C_PRICE':
            hasModalOption = currentStateMapping && currentStateMapping?.showPricingModal ? true : false;
            break;

          case 'U_PRICE':
            hasModalOption = currentStateMapping && currentStateMapping?.showUpgradeModal ? true : false;
            break;

          case 'U_MAX':
            hasModalOption = currentStateMapping && currentStateMapping?.showUpgradeSuccessMAXInfo ? true : false;
            break;

          case 'U_NA':
            hasModalOption = currentStateMapping && currentStateMapping?.showUpgradeSuccessNAInfo ? true : false;
            break;
        }
        setCurrentStateHasModalOption(hasModalOption);
      }
    };

    const { dataLists } = pageProps;
    const MARSHA: string = marsha.toUpperCase() ?? '';
    const zList = (dataLists?.zParamList?.toUpperCase() ?? '').split(',');
    const mvList = (dataLists?.mVParamList?.toUpperCase() ?? '').split(',');
    // const msList = (dataLists?.mSParamList ?? '').split(', ');
    const zoneMapping: Record<string, any> = {
      ...dataLists?.zoneValues,
    };

    // reading usage value from data attr from AEM to send correct USAGE in LSP payload for Tier1&2.
    // Also mapping correct SP value in all scenarios payload
    let usageAttr = '';

    if (experienceSegment.toLowerCase().includes('basic')) {
      usageAttr = 'MEMBER';
    } else if (experienceSegment.toLowerCase().includes('elite')) {
      usageAttr = 'ELITE';
    }

    // reading valid Z values from data attr to match and assign Z value, in case of invalid or null "GUEST" is default value
    let Z = '';
    if (urlQueryParams['Z'] !== null && zList.includes((urlQueryParams['Z'] ?? '').toUpperCase())) {
      Z = (urlQueryParams['Z'] ?? 'GUEST').toUpperCase();
    } else {
      Z = 'GUEST';
    }

    const lrList =
      Z === 'GUEST'
        ? (dataLists?.lrParamList ?? '')
            ?.toUpperCase()
            .split(',')
            ?.filter(lr => lr !== 'AF_AO')
        : (dataLists?.lrParamList ?? '')?.toUpperCase().split(',');

    // reading valid LR values from data attr to match and assign LR value
    let LR = '';
    if (urlQueryParams['LR'] && lrList?.includes((urlQueryParams['LR'] ?? '').toUpperCase())) {
      LR = (urlQueryParams['LR'] ?? '').toUpperCase();
    }

    // reading valid MV values from data attr to match and assign MV value
    let MV = '';
    if (urlQueryParams['MV'] !== null && mvList.includes((urlQueryParams['MV'] ?? '').toUpperCase())) {
      MV = (urlQueryParams['MV'] ?? '').toUpperCase();
    }

    const updatedState = state && state === 'preconnect' ? 'CONNECT' : state;
    const ACTION = (urlQueryParams['AT'] as string)?.toUpperCase() ?? updatedState?.toUpperCase() ?? '';
    // reading valid USAGE from URL. Required for payload in nologin/freeconnection&access LSP payload
    const USAGE = (urlQueryParams['U'] as string)?.toUpperCase() ?? '';
    // reading duration value from url, default value is 7
    const DURATION = urlQueryParams['DURATION'] ?? '7';
    // reading duration initial value from url, default is 1
    const DD = (urlQueryParams['DD'] as string)?.toUpperCase() ?? '1';
    const ERR = (urlQueryParams['ERR'] as string)?.toUpperCase() ?? '';
    const DOMAIN = (window?.location?.href ?? '').split('einterface')[0];
    const MS: string | null = (urlQueryParams['MS'] as string)?.toUpperCase() ?? null;
    const postEndpoint = urlQueryParams['L_URL'] ?? '';
    const SP = (urlQueryParams['SP'] as string)?.toUpperCase() ?? '';
    const ppv5Enrollment = urlQueryParams['ppv5Enrollment'] ?? '';

    let APS: string | undefined = undefined;

    //reading valid MS values from data attr to match with URL value and send to LSP Payload
    if ((ACTION === 'PRECONNECT' || ACTION === 'CONNECT' || ACTION === 'CONNECTED') && MS === null) {
      // calculate apsValue
      let apsValue: string | undefined = undefined;
      if (experienceSegment?.toLowerCase()?.includes('member-')) {
        apsValue = 'N';
      } else if (experienceSegment?.toLowerCase()?.includes('basic-')) {
        apsValue = 'N';
      } else if (experienceSegment?.toLowerCase()?.includes('elite-')) {
        apsValue = 'E';
      } else {
        apsValue = '';
      }
      APS = apsValue;
    }

    if (MV || ERR) {
      setShowInternetBarModal(true);
      if (MV) {
        setModalType(MV);
        urlAsPerMapping(MV, ERR);
      }
    } else {
      if (currentStateMapping && currentStateMapping?.showLoginModal) {
        setCurrentStateHasModalOption(true);
      }
    }

    const loyaltyEnrollmentSourceCode = dataLists?.loyaltyEnrollmentSourceCode as unknown as LOYALTY_ENROLLMENT_SOURCE;
    if (ppv5Enrollment?.toString()?.toLowerCase() === 'true' && getEnrolledUserFlag(loyaltyEnrollmentSourceCode)) {
      setCurrentStateHasModalOption(true);
      setIsNoLoginInterfaceModal(false);
      if (Z?.toUpperCase() === 'GUEST') {
        setShowInternetBarModal(true);
        if (zoneMapping[Z ?? ''] === 'SF') {
          if (!(LR?.toString()?.toUpperCase() === 'AF_AC' || LR?.toString()?.toUpperCase() === 'AF_MO')) {
            setModalType(MV ? MV : 'LOGIN');
            let showSeparator = true;
            if (LR) {
              showSeparator =
                currentStateMapping.loginModal.userAccessCodeLabel ||
                currentStateMapping.loginModal.requestAccessCodeLabel;
            }
            setLoyaltyCurrentStateMapping({
              ...currentStateMapping,
              showLoginModal: MV ? currentStateMapping?.showLoginModal : true,
              loginModal: {
                ...currentStateMapping.loginModal,
                connectNowLabel: true,
                default: true,
                separatorLabel: showSeparator,
              },
            });
          } else {
            setModalType(MV ? MV : 'LOYALTY_WELCOME');
          }
        } else if (zoneMapping[Z ?? ''] === 'FP') {
          setModalType(MV ? MV : 'LOGIN');
          setLoyaltyCurrentStateMapping({
            ...currentStateMapping,
            showLoginModal: MV ? currentStateMapping?.showLoginModal : true,
            loginModal: {
              ...currentStateMapping.loginModal,
              default: true,
            },
          });
        }
      } else if (Z?.toUpperCase() === 'PUBLIC' || Z?.toUpperCase() === 'LOBBY') {
        if (!(LR && LR.toUpperCase() !== 'TCTC') || currentStateMapping?.noLoginInterfaceModal) {
          setShowInternetBarModal(true);
          setModalType('LOYALTY_WELCOME');
        } else {
          setShowInternetBarModal(true);
          if (LR?.toUpperCase() === 'AF_AO') {
            setModalType('ACCESS');
          } else {
            setModalType(MV ? MV : 'LOGIN');
          }
        }
      }
    }

    return {
      ACTION,
      APS,
      DD,
      DOMAIN,
      DURATION,
      ERR,
      LR,
      MS,
      MV,
      ppv5Enrollment: ppv5Enrollment === 'true' || false,
      SP,
      USAGE,
      Z,
      MARSHA,
      postEndpoint,
      zoneMapping,
      usageAttr,
      loyaltyEnrollmentSourceCode,
    };
  }, []);

  useEffect(() => {
    if (pageProps) {
      const internetbarInitialValues = initializeInternetBarData();
      setInternetBarDataValues({
        ...internetbarInitialValues,
      });
    }
  }, [initializeInternetBarData, pageProps, preProcessorResponse]);

  useEffect(() => {
    if (serviceData?.connectBtnClick) {
      handleBtnClick('connect');
    }
  }, [handleBtnClick, serviceData?.connectBtnClick]);

  useEffect(() => {
    const checkPropertyLinks = (item: string): boolean => {
      return (
        item.length > 1 &&
        !item.includes('einterface/v5') &&
        !item.includes('tel:') &&
        // eslint-disable-next-line no-script-url
        !item.includes('javascript:void(0)')
      );
    };

    const anchorList = (document.getElementsByTagName('a') ?? []) as unknown as HTMLAnchorElement[];
    if (cna || sameTab) {
      for (const element of anchorList) {
        if (element?.hasAttribute('target')) {
          element?.removeAttribute('target');
        }
      }
    } else {
      const bottomFoldElements: NodeListOf<HTMLElement> = document.querySelectorAll(
        '.amenities-list, .key-amenities, .nearby-things-to-do'
      );
      bottomFoldElements?.forEach((ele: HTMLElement) => {
        const elements = (ele.getElementsByTagName('a') ?? []) as unknown as HTMLAnchorElement[];
        for (const element of elements) {
          const item = element.getAttribute('href') ?? '';
          // check if link is not ppv5 or telephone
          if (checkPropertyLinks(item)) {
            element.setAttribute('target', '_blank');
          }
        }
      });
    }
  }, [cna, sameTab]);

  useEffect(() => {
    const { dataLists } = pageProps;
    const { segmentId, enrollmentSourceCode } = dataLists;
    const anchorList = (document.getElementsByTagName('a') ?? []) as unknown as HTMLAnchorElement[];
    for (const element of anchorList) {
      const description = element?.getAttribute('href') ?? '';
      if (description && description?.toString()?.includes('/loyalty/createAccount/createAccountPage1.mi')) {
        if (unknownFreeConnection) {
          let joinNowLink = pageProps?.joinButtonLink;
          const { dataLists } = pageProps;
          const { loyaltyEnrollmentSourceCode } = dataLists;
          const returnToURL = `${asPath}&ppv5Enrollment=true`;
          if (joinNowLink && joinNowLink?.toString()?.includes('/loyalty/createAccount/createAccountPage1.mi')) {
            joinNowLink =
              joinNowLink && joinNowLink?.toString()?.includes('/loyalty/createAccount/createAccountPage1.mi?')
                ? `${joinNowLink}&enrollmentSourceCode=${loyaltyEnrollmentSourceCode}&returnToUrl=${encodeURIComponent(
                    returnToURL
                  )}`
                : `${joinNowLink}?enrollmentSourceCode=${loyaltyEnrollmentSourceCode}&returnToUrl=${encodeURIComponent(
                    returnToURL
                  )}`;
          }
          setLoyaltyJoinNow(joinNowLink);
        } else {
          element?.setAttribute(
            'href',
            `${
              description && description?.toString()?.includes('/loyalty/createAccount/createAccountPage1.mi?')
                ? `${description}&segmentId=${segmentId}&enrollmentSourceCode=${enrollmentSourceCode}&returnToUrl=${encodeURIComponent(
                    asPath
                  )}`
                : `${description}?segmentId=${segmentId}&enrollmentSourceCode=${enrollmentSourceCode}&returnToUrl=${encodeURIComponent(
                    asPath
                  )}`
            }`
          );
        }
      } else if (description && description?.toString()?.includes('/sign-in.mi')) {
        if (description.toString()?.includes('_PPV5URL_')) {
          element?.setAttribute(
            'href',
            `${description.toString()?.replace(/_PPV5URL_/g, encodeURIComponent(encodeURIComponent(asPath)))}`
          );
        }
      }
    }

    document.querySelector('.m-global-nav-top-center')?.querySelector('a')?.querySelector('.lazy-image')?.remove();
    const globalNav = document.querySelector('.m-global-nav-top-center')?.querySelector('a');
    globalNav?.setAttribute('aria-label', 'Marriott Bonvoy');
    const globalNavLogo = document.createElement('DIV');
    document.querySelector('.m-global-nav-main-menu')?.querySelector('a')?.querySelector('.lazy-image')?.remove();
    const globalNavMenu = document.querySelector('.m-global-nav-main-menu')?.querySelector('a');
    globalNavMenu?.setAttribute('aria-label', 'Marriott Bonvoy');
    const globalNavMenuLogo = document.createElement('DIV');
    if (document.documentElement.lang.toUpperCase().indexOf('CN') > -1) {
      globalNavLogo.setAttribute('class', 't-program-logo-cn-s');
      globalNavMenuLogo.setAttribute('class', 't-program-logo-cn-s');
    } else {
      globalNavLogo.setAttribute('class', 't-program-logo-xs');
      globalNavMenuLogo.setAttribute('class', 't-program-logo-xs');
    }
    globalNav?.appendChild(globalNavLogo);
    globalNavMenu?.appendChild(globalNavMenuLogo);

    // @TODO - remove the globalNavLanguageClosebtn logic once it is fixed from AEM
    const globalNavLanguageClosebtn = document
      .querySelector('.m-global-nav-language-container .m-global-nav-language-content')
      ?.querySelector('.m-global-nav-language-close .icon-clear.icon-inverse + .sr-only');
    if (globalNavLanguageClosebtn) {
      (globalNavLanguageClosebtn as HTMLElement).textContent =
        pageProps?.requestAccessCodeModel?.racClosingLabel ?? 'Close';
    }
  }, [pageProps]);

  const getConnectUpgradeBtn = () => {
    if (
      !currentStateMapping?.isConnectState &&
      (!currentStateMapping?.showUpgradeModal || state === statesConstant?.connected)
    ) {
      return (
        <Button
          className={clsx('m-button-primary', `${stateCSS?.internetBarConnectButton}`)}
          isLink={true}
          href={pageProps?.upgradeButtonLink}
          target={sameTab || cna ? '_self' : '_blank'}
          custom_click_track_value={`Internet Bar | ${
            currentStateMapping?.isConnectState ? 'Connect' : 'Upgrade'
          } | INTERNAL`}
          ariaLabel={
            ((currentStateMapping?.connectUpgradeBtn &&
              pageProps?.[currentStateMapping?.connectUpgradeBtn as keyof AEMInternetBarProps]) ||
              pageProps?.connectButtonLabel) as string
          }
        >
          <span data-testid="internet-bar-upgrade-link">
            {(currentStateMapping?.connectUpgradeBtn &&
              pageProps?.[currentStateMapping?.connectUpgradeBtn as keyof AEMInternetBarProps]) ||
              pageProps?.connectButtonLabel}
          </span>
        </Button>
      );
    } else {
      return (
        <Button
          className={clsx(
            'm-button-primary',
            `${stateCSS?.internetBarConnectButton}`,
            `${isDefaultConnectNowBtnLoading && currentStateMapping?.isConnectState ? 'disabled' : ''}`
          )}
          isDisabled={isDefaultConnectNowBtnLoading && currentStateMapping?.isConnectState}
          callback={() => {
            currentStateMapping?.isConnectState ? handleBtnClick('connect') : handleBtnClick('upgrade');
          }}
          custom_click_track_value={`Internet Bar | ${
            currentStateMapping?.isConnectState ? 'Connect' : 'Upgrade'
          } | INTERNAL`}
          ariaLabel={
            ((currentStateMapping?.connectUpgradeBtn &&
              pageProps?.[currentStateMapping?.connectUpgradeBtn as keyof AEMInternetBarProps]) ||
              pageProps?.connectButtonLabel) as string
          }
        >
          {isDefaultConnectNowBtnLoading ? (
            <div className="m-spinner-s" data-testid="activate-spinner"></div>
          ) : (
            <span data-testid="internet-bar-connect-upgrade-btn">
              {(currentStateMapping?.connectUpgradeBtn &&
                pageProps?.[currentStateMapping?.connectUpgradeBtn as keyof AEMInternetBarProps]) ||
                pageProps?.connectButtonLabel}
            </span>
          )}
        </Button>
      );
    }
  };

  const defaultLayout = (
    <>
      <div
        className={clsx(
          `col-12 pr-0 pl-0 t-font-s internet-bar-content__description-container internet-bar-content-${state}__description-container`,
          `${stateCSS?.internetBarContentDescriptionContainer}`
        )}
      >
        {/* 3. Welcome Text */}
        {currentStateMapping?.welcomeText && (
          <p
            className={clsx(
              'internet-bar-content__description-container__description',
              `${stateCSS?.internetBarWelcomeText}`
            )}
            data-testid="internet-bar-welcome-text"
            dangerouslySetInnerHTML={{
              __html: welcomeText,
            }}
          ></p>
        )}
        {/* 4. Description */}
        {currentStateMapping?.description && (
          <p
            className={clsx(
              'internet-bar-content__description-container__description',
              `${stateCSS?.internetBarDescription}`
            )}
            data-testid="internet-bar-description"
            dangerouslySetInnerHTML={{
              __html: descriptionText,
            }}
          ></p>
        )}
      </div>
      {showButtonContainer && (
        <div
          className={clsx(
            `col-12 internet-bar-content__button-container internet-bar-content-${state}__button-container`,
            `${stateCSS?.internetBarButtonContainer}`
          )}
        >
          <div
            className={clsx(
              `d-flex flex-column justify-content-center px-2 px-xl-0 internet-bar-content-${state}__button-content internet-bar-content__button-container-item`,
              `${stateCSS?.internetBarButtonContentContainer}`
            )}
          >
            {/* 5. Sign In  - Primary Button  */}
            {currentStateMapping?.signInButton?.signInButtonLabel &&
              currentStateMapping?.signInButton?.signInButtonLink && (
                <Button
                  className={`internet-bar-content-${state}__button m-button  ${
                    stateCSS.internetBarSigninButton ? stateCSS.internetBarSigninButton : 'm-button-secondary'
                  }`}
                  custom_click_track_value="Internet Bar | Sign In | INTERNAL"
                  isLink={true}
                  target="_blank"
                  href={(
                    pageProps?.[
                      currentStateMapping?.signInButton?.signInButtonLink as keyof AEMInternetBarProps
                    ] as string
                  )?.toString()}
                  ariaLabel={
                    (pageProps?.[currentStateMapping?.signInText as keyof AEMInternetBarProps] ||
                      pageProps?.signInButtonLabel) as string
                  }
                >
                  <span data-testid="internet-bar-sign-in">
                    {pageProps?.[currentStateMapping?.signInText as keyof AEMInternetBarProps] ||
                      pageProps?.signInButtonLabel}
                  </span>
                </Button>
              )}

            {/* 6. Join Now  - Primary / Secondary Button  */}
            {currentStateMapping?.joinNowButton?.joinButtonLabel &&
              currentStateMapping?.joinNowButton?.joinButtonLink && (
                <Button
                  className={`m-button d-flex align-items-center justify-content-center mb-4 ${
                    stateCSS.internetBarJoinNowButton ? stateCSS.internetBarJoinNowButton : 'm-button-primary'
                  } `}
                  isLink={true}
                  custom_click_track_value={`Internet Bar | Join Now | INTERNAL`}
                  href={
                    pageProps?.[
                      currentStateMapping?.joinNowButton?.joinButtonLink as keyof AEMInternetBarProps
                    ] as string
                  }
                  target={
                    pageProps?.[
                      currentStateMapping?.joinNowButton?.joinButtonLinkInNewTab as keyof AEMInternetBarProps
                    ] as string
                  }
                  ariaLabel={
                    (pageProps?.[currentStateMapping?.joinNowButton?.joinButtonLabel as keyof AEMInternetBarProps] ||
                      pageProps?.joinButtonLabel) as string
                  }
                >
                  <span data-testid="internet-bar-join-now">
                    {pageProps?.[currentStateMapping?.joinNowButton?.joinButtonLabel as keyof AEMInternetBarProps] ||
                      pageProps?.joinButtonLabel}
                  </span>
                </Button>
              )}

            {/* 7. Connect / Upgrade - Primary Button  */}
            {currentStateMapping?.connectUpgradeBtn && getConnectUpgradeBtn()}
          </div>
        </div>
      )}
      <div
        className={clsx(
          `col-12 internet-bar-content__footer-container internet-bar-content-${state}__footer-container`,
          `${stateCSS?.internetBarFooterContainer}`
        )}
      >
        {/* 8. Terms and Condition Text  */}
        {currentStateMapping?.termsAndConditions && (
          <p
            className={clsx(
              `mb-0 t-font-xs internet-bar-content__footer-container__tnc-text`,
              `${stateCSS?.internetBarTermsAndConditions}`
            )}
            data-testid="internet-bar-terms-condition"
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsText,
            }}
          ></p>
        )}
        {/* 9. Sign In text */}
        {currentStateMapping?.signInText && (
          <p
            className="t-font-xs pb-4 mb-1 mb-xl-0 internet-bar-content__footer-container__signin-text"
            dangerouslySetInnerHTML={{
              __html: signInText,
            }}
            data-testid="internet-bar-sign-in-text"
          ></p>
        )}
      </div>
    </>
  );

  const loyaltyLayout = (
    <div className={clsx('loyalty-container', `${stateCSS?.internetBarLoyaltyContainer}`)}>
      <div
        className={clsx(
          'd-flex mb-5',
          `${loyaltyFlip ? 'flex-column-reverse flex-md-row-reverse' : 'flex-column flex-md-row'}`
        )}
      >
        <div className="loyalty-container-card1 col-12 col-md-6 p-0 d-flex flex-column justify-content-between align-items-center">
          <div className={clsx('d-flex flex-column', `${loyaltyFlip ? 'pt-5 pt-md-0' : ''}`)}>
            <p
              className={clsx(
                't-font-s mb-0 loyalty-description t-font-weight-m pb-2',
                `${stateCSS?.internetBarLoyaltyDescription}`
              )}
            >
              {pageProps?.loyaltyJoinnowScenarioText}
            </p>
            <p className={clsx('t-font-s', `${stateCSS?.internetBarLoyaltySubDescription}`)}>
              {pageProps?.loyaltyJoinnowScenarioDescription}
            </p>
          </div>
          <Button
            className={clsx('m-button-primary pt-2 loyalty-buttons', `${stateCSS?.internetBarLoyaltyButtons}`)}
            isLink={true}
            href={loyaltyJoinNow}
            target={pageProps?.joinButtonLinkInNewTab}
            custom_click_track_value={`Internet Bar | ${pageProps?.joinButtonLabel} | EXTERNAL`}
            ariaLabel={pageProps?.joinButtonLabel}
          >
            <span>{pageProps?.joinButtonLabel}</span>
          </Button>
        </div>
        <div className="loyalty-container-card2 col-12 col-md-6 d-flex flex-column justify-content-between align-items-center">
          <div
            className={`${
              loyaltyFlip ? '' : 'd-flex flex-column justify-content-between align-items-center flex-1 pt-5 pt-md-0'
            }`}
          >
            <p
              className={clsx(
                't-font-s mb-0 loyalty-description t-font-weight-m pb-2',
                `${stateCSS?.internetBarLoyaltyDescription}`
              )}
            >
              {pageProps?.loyaltyConnectScenarioText}
            </p>
            <p className={clsx('t-font-s', `${stateCSS?.internetBarLoyaltySubDescription}`)}>
              {pageProps?.loyaltyConnectScenarioDescription}
            </p>
            <Button
              className={clsx(
                'm-button-primary loyalty-buttons',
                `${stateCSS?.internetBarLoyaltyButtons}`,
                `${isLoyaltyConnectNowBtnLoading ? 'disabled' : ''}`
              )}
              isDisabled={isLoyaltyConnectNowBtnLoading}
              custom_click_track_value={`Internet Bar | ${pageProps?.loyaltyConnectNowButton} | INTERNAL`}
              ariaLabel={pageProps?.loyaltyConnectNowButton}
              callback={() => {
                handleBtnClick('connectnow');
              }}
            >
              {isLoyaltyConnectNowBtnLoading ? (
                <div className="m-spinner-s" data-testid="activate-spinner"></div>
              ) : (
                <span>{pageProps?.loyaltyConnectNowButton}</span>
              )}
            </Button>
          </div>
        </div>
      </div>

      {currentStateMapping?.termsAndConditions && (
        <p
          className={clsx(
            `mb-0 t-font-xs internet-bar-content__footer-container__tnc-text`,
            `${stateCSS?.internetBarTermsAndConditions}`
          )}
          data-testid="internet-bar-terms-condition"
          dangerouslySetInnerHTML={{
            __html: termsAndConditionsText,
          }}
        ></p>
      )}
    </div>
  );

  return (
    <StyledInternetBar data-testid="internet-box" data-component-name="o-ppv5-internet-box" className="relative">
      {currentStateHasModalOption && !isNoLoginInterfaceModal && modalType && (
        <div data-testid={`internet-bar-modal-${state}`}>
          <InternetBarModal
            showInternetBarModal={showInternetBarModal}
            internetBarDataValues={{ ...internetBarDataValues }}
            modalType={modalType}
            accessModal={pageProps?.accessCodeModel}
            login={pageProps?.loginModel}
            currentStateMapping={
              urlQueryParams['ppv5Enrollment']?.toString()?.toLowerCase() === 'true' &&
              getEnrolledUserFlag(internetBarDataValues?.['loyaltyEnrollmentSourceCode'])
                ? { ...loyaltyCurrentStateMapping }
                : { ...currentStateMapping }
            }
            handleCloseBtnClick={handleCloseBtnClick}
            wifiUpgradeGENError={pageProps?.wifiUpgradeErrorFieldsBean}
            wifiUpgradeLR={pageProps?.wifiUpgradeLrFieldsBean}
            wifiUpgradeMAX={pageProps?.wifiUpgradeMaxFieldsBean}
            wifiUpgradeNoCC={pageProps?.wifiUpgradeCreditFieldsBean}
            wifiUpgradePMS={pageProps?.wifiUpgradePmsFieldsBean}
            wifiUpgradeSuccessMAX={pageProps?.wifiUpgradeSuccessMaxFieldsBean}
            wifiUpgradeSuccessNA={pageProps?.wifiUpgradeSuccessNaFieldsBean}
            requestAccessCodeModal={pageProps?.requestAccessCodeModel}
            internetPricingModel={pageProps?.internetPricingModel}
          />
        </div>
      )}
      {currentStateMapping && pageProps && (
        <div className="internet-bar-container color-scheme1 mx-5 mx-md-auto">
          <div
            className={clsx(
              `container internet-bar-content ${
                unknownFreeConnection && !LOYALTY_DISABLED_ZONES?.includes(urlQueryParams['Z']?.toUpperCase())
                  ? `internet-bar-content-${state}-loyalty`
                  : `internet-bar-content-${state}`
              }`,
              `${stateCSS?.internetBarContent}`
            )}
          >
            <div
              className={clsx(
                'col-12 d-flex flex-column justify-content-center px-0 text-center align-items-center',
                `${stateCSS?.internetBarContentContainer}`
              )}
            >
              {/* 1. Internet Bar Icon Configuration */}
              {showIcon && (
                <span
                  className={clsx(
                    `t-accent-color d-inline-block ${internetBarIcons?.[state]?.iconName} internet-bar-content__icon internet-bar-content-${state}__icon`,
                    `${stateCSS?.internetBarIcon}`
                  )}
                  data-testid="internet-bar-icon"
                ></span>
              )}
              {/* 2. Title */}
              {isWelcomeTitle ? (
                <>
                  <h3
                    className={`mt-4 mb-3 pt-xl-4 internet-bar-content__title internet-bar-content-${state}__title`}
                    data-testid="internet-bar-welcome-title"
                  >
                    {pageProps?.[currentStateMapping?.title as keyof AEMInternetBarProps] || ''}
                  </h3>
                  {/* Separator */}
                  <div
                    className={`t-accent-color mb-3 mb-xl-4 internet-bar-content__separator internet-bar-content-${state}__separator`}
                  ></div>
                </>
              ) : (
                <h3
                  className={clsx('col-12 internet-bar-content__title', `${stateCSS?.internetBarNotWelcomeTitle}`)}
                  data-testid="internet-bar-welcome-title"
                >
                  {pageProps?.[currentStateMapping?.title as keyof AEMInternetBarProps] || ''}
                </h3>
              )}

              {!(unknownFreeConnection && !LOYALTY_DISABLED_ZONES?.includes(urlQueryParams['Z']?.toUpperCase()))
                ? defaultLayout
                : loyaltyLayout}
            </div>
          </div>
        </div>
      )}
    </StyledInternetBar>
  );
};

export const InternetBarConfig = {
  emptyLabel: 'InternetBarComponent',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/hero-banner`,
} as Config<MappedComponentProperties>;

export const InternetBarEditable = (props: InternetBarProps) => (
  <EditableComponent config={InternetBarConfig} {...props}>
    <InternetBar {...props} />
  </EditableComponent>
);
