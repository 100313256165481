/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { OfferOrigin, Image, Caption } from './OfferCardListing.types';
import {
  API_ACCEPT_ASPECT_RATIO_WIDE,
  IMAGE_CLASSIC_COMPRESS_PARAM,
  API_ACCEPT_CAPTION_LENGTH,
  IMAGE_ALT_TEXT,
  IMAGE_LEFT,
  PREVIEW_LIST_HEADING,
  PREVIEW_LIST_DESC,
  CARD_CONTENTS_LEFT,
  API_ACCEPT_ASPECT_RATIO_CLASSIC,
  OFFER_CARDLIST_ERROR_POLICY,
} from '../../constants/OfferCardConstants';
import { DETAILS_HIDDEN, DETAILS_SHOWN } from '../../constants/OfferCardConstants';
import { Button, CardHorizontalFeature, Heading, Types } from '@marriott/mi-ui-library';
import { OFFER_IMAGE_CACHE_DOMAIN } from '../../constants/OffersHeroConstants';
import config from '../../lib/config';
import { generateOfferDetailUrl, getFallbackImg } from '../../utils/CommonUtils';
import { phoenixOfferCardListingQuery, phoenixOffersCardListingPreview } from '@marriott/mi-offers-graphql';
import { OfferCardListingProps } from './OfferCardListing.types';
import { CardListUXLMockJson } from './__mock__/OfferCardListUXL.mock';
import { CardListApiMockJson } from './__mock__/OfferCardListModel.mock';
import { processDomainUrl } from '../../utils/OfferUtils';
import { addSubDirectoryPrefix, processAcceptLang } from '../../utils/OfferUtils';
import { INTERNAL_CTA_TYPE } from '../../constants/CommonConstants';

export const CardList: React.FC<OfferCardListingProps> = props => {
  const { offersData, isAuthorMode, model, acceptLanguage } = props;
  const isPreview = offersData?.isPreview === 'true' ? true : false;
  const isServer = !(typeof window !== 'undefined' && window.document);
  const offerObj = offersData;
  const [showMoreDetails, setShowMoreDetails] = useState(DETAILS_HIDDEN);
  const [tabletMode, setTabletMode] = useState(false);
  const [offerCardResult, setOfferCardResult] = useState<any[]>([]);
  const acceptLang = processAcceptLang(acceptLanguage ?? '');
  const showMoreToggle = (): void => {
    if (showMoreDetails === DETAILS_HIDDEN) {
      setShowMoreDetails(DETAILS_SHOWN);
    } else {
      setShowMoreDetails(DETAILS_HIDDEN);
    }
  };
  const variables = {
    input: {
      queries: [
        {
          id: 'properties',
          values: offerObj?.marsha,
        },
      ],
    },
  };
  const trackingProperties = {
    trackingContentPosition: 'trackingContentPosition',
    trackingOfferType: 'trackingOfferType',
    atCCeVar48: 'atCCeVar48',
    trackingDescription: model?.trackingProperties?.trackingDescription,
    trackingTag: 'trackingTag',
    isCoBrand: false,
    clickTrack: model?.trackingProperties?.clickTrack,
    impressionTrack: true,
    impressionCount: true,
    impressionEventType: 'impressionEventType',
    merchandisingCategory: 'merchandisingCategory',
    additionalTrackingVariables: 'additionalTrackingVariables',
    location: model?.trackingProperties?.location,
    payloadType: 'payloadType',
    compName: 'compName',
    enableScrollingBehavior: model?.trackingProperties?.enableScrollingBehavior,
    cardLocation: model?.trackingProperties?.cardLocation,
    leftArrowDesc: model?.trackingProperties?.leftArrowDesc,
    rightArrowDesc: model?.trackingProperties?.rightArrowDesc,
    description: model?.trackingProperties?.description,
  };

  const queryGQ = isPreview ? phoenixOffersCardListingPreview : phoenixOfferCardListingQuery;
  // useQuery
  const { data } = useQuery(queryGQ, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    errorPolicy: OFFER_CARDLIST_ERROR_POLICY,
    variables: isPreview ? { offerPreviewId: offerObj?.offerId } : variables,
    context: {
      headers: {
        // 'x-request-id': requestId,
        'accept-language': acceptLang,
      },
    },
    skip: isAuthorMode,
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reformPreviewResponse = (response: any) => {
    return {
      offersSearch: {
        edges: [{ node: response?.offerPreview?.offer }, { node: response?.offerPreview?.offer }],
      },
    };
  };

  const handleResize = () => {
    const md = window.matchMedia('(min-width:768px) and (max-width: 991px)');
    if (md?.matches) {
      setTabletMode(true);
    } else {
      setTabletMode(false);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOffers = async (offerData: any) => {
    const offerListId = isAuthorMode ? CardListApiMockJson.offerId.toUpperCase() : offerObj.offerId.toUpperCase();
    const offersMap = offerData?.offersSearch?.edges
      ?.filter((apiResOffer: OfferOrigin) => apiResOffer?.node?.id.toUpperCase() !== offerListId)
      .map((offer: OfferOrigin) => {
        const ASPECT_RATIO_UXL_FIELD_MAPPING = {
          WIDE: 'wideHorizontal',
          CLASSIC: 'classicHorizontal',
        };
        let image = '';
        const objKey = API_ACCEPT_ASPECT_RATIO_WIDE as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
        const DACDeskImagePath =
          offer?.node?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKey]];

        if (DACDeskImagePath) {
          image = OFFER_IMAGE_CACHE_DOMAIN + DACDeskImagePath;
        } else {
          image =
            OFFER_IMAGE_CACHE_DOMAIN +
            (offer?.node?.photos?.images?.filter(
              ({ aspectRatio }: Image) => aspectRatio?.toUpperCase() === API_ACCEPT_ASPECT_RATIO_WIDE
            )?.[0]?.url ?? getFallbackImg(config.FALLBACK_IMG_URI, API_ACCEPT_ASPECT_RATIO_WIDE));
        }
        if (image) {
          image += IMAGE_CLASSIC_COMPRESS_PARAM;
        }
        let tabletImage = '';
        const objKeytab = API_ACCEPT_ASPECT_RATIO_CLASSIC as keyof typeof ASPECT_RATIO_UXL_FIELD_MAPPING;
        const DACTabImagePath =
          offer?.node?.media?.primaryImage?.imageUrls?.[ASPECT_RATIO_UXL_FIELD_MAPPING?.[objKeytab]];
        if (DACTabImagePath) {
          tabletImage = OFFER_IMAGE_CACHE_DOMAIN + DACTabImagePath;
        } else {
          tabletImage =
            OFFER_IMAGE_CACHE_DOMAIN +
            (offer?.node?.photos?.images?.filter(
              ({ aspectRatio }: Image) => aspectRatio?.toUpperCase() === API_ACCEPT_ASPECT_RATIO_CLASSIC
            )?.[0]?.url ?? getFallbackImg(config.FALLBACK_IMG_URI, API_ACCEPT_ASPECT_RATIO_CLASSIC));
        }
        if (image) {
          image += IMAGE_CLASSIC_COMPRESS_PARAM;
        }
        const alt = offer?.node?.photos?.captions?.filter(
          ({ length }: Caption) => length?.toUpperCase() === API_ACCEPT_CAPTION_LENGTH
        )?.[0]?.caption;
        const updatedUrl = generateOfferDetailUrl(
          offer.node.urlTitle,
          offer.node.id,
          offer.node.seoNickname,
          isAuthorMode ? CardListApiMockJson.marsha : offerObj.marsha,
          [],
          isAuthorMode ? CardListApiMockJson.locale : offerObj.locale,
          true
        );
        return {
          id: offer?.node?.id,
          alt: alt,
          image: image,
          tabletImage: tabletImage,
          title: offer?.node?.title,
          description: offer?.node?.descriptionTeaser,
          link: addSubDirectoryPrefix(processDomainUrl(updatedUrl[0]['url'])),
        };
      });
    setOfferCardResult(offersMap);
    return offersMap;
  };

  const OffersCardListRef = useRef(null);

  useEffect(() => {
    let results = null;
    if (data) {
      results = data;
      getOffers(isPreview ? reformPreviewResponse(data) : results);
    } else if (isAuthorMode) {
      results = CardListUXLMockJson;
      getOffers(results);
    }
    window.addEventListener('resize', handleResize);
  }, [data]);

  return (
    <>
      {!isPreview ? (
        <div className={`list-header`}>
          {offerCardResult?.length > 0 && (
            <Heading
              titleText={model?.titleText}
              variation={Types.headingType.title}
              fontSize={Types.size.medium}
              customClass={clsx('m-0')}
            />
          )}
        </div>
      ) : (
        <div>
          {
            <>
              {offerCardResult?.length > 0 && (
                <Heading
                  titleText={
                    model?.offerCardsListingPreviewTitle ? model.offerCardsListingPreviewTitle : PREVIEW_LIST_HEADING
                  }
                  variation={Types.headingType.title}
                  fontSize={Types.size.medium}
                  customClass={clsx('m-0')}
                />
              )}
              {offerCardResult?.length > 0 && (
                <Heading
                  titleText={
                    model?.offerCardsListingPreviewDescription
                      ? model.offerCardsListingPreviewDescription
                      : PREVIEW_LIST_DESC
                  }
                  variation={Types.headingType.subtitle}
                  fontSize={Types.size.medium}
                  customClass={clsx('mt-1')}
                />
              )}
            </>
          }
        </div>
      )}

      <div className={`list-body  `}>
        {offerCardResult?.map((card: any, index: number) => {
          let imageUri = null;
          let imageUriLg = null;
          if (card.image.includes('/is/image')) {
            imageUri = `${card.image}?wid=365&fit=constrain`;
            imageUriLg = `${card.image}?wid=800&fit=constrain`;
          } else {
            imageUri = `${card.image}?downsize=365px:*`;
            imageUriLg = `${card.image}?downsize=800px:*`;
          }
          const CardListImage = tabletMode ? `${card.tabletImage}` : `${card.image}`;
          return (
            <CardHorizontalFeature
              assetVariation="image"
              header={card?.title ?? ''}
              description={card?.description ?? ''}
              contentBlockAlignment={CARD_CONTENTS_LEFT}
              imageAlignment={IMAGE_LEFT}
              openInNewTab={model?.openInaNewTab}
              trackingProperties={trackingProperties}
              styleclass={`${
                showMoreDetails === DETAILS_HIDDEN && index > 2 ? 'cardstylehidden' : 'cardstylevisible'
              } offer-card`}
              ctaType={model?.ctaType}
              dynamicMedia={{
                assetPath: CardListImage,
                altText: card?.title ? card.title : IMAGE_ALT_TEXT,
                dynamic: false,
                renditions: [
                  {
                    renditionPath: imageUriLg,
                    mediaValue: '768px',
                    width: 0,
                    dynamic: true,
                    damPath: CardListImage,
                    mediaQuery: 'min-width',
                    height: 0,
                  },
                  {
                    renditionPath: imageUri,
                    mediaValue: '576px',
                    width: 0,
                    dynamic: true,
                    damPath: CardListImage,
                    mediaQuery: 'min-width',
                    height: 0,
                  },
                  {
                    renditionPath: imageUri,
                    mediaValue: '576px',
                    width: 0,
                    dynamic: true,
                    damPath: CardListImage,
                    mediaQuery: 'max-width',
                    height: 0,
                  },
                ],
              }}
              linkUrl={card.link}
              linkText={model?.ctaLabel}
              custom_click_track_value={`${model?.id}|${model?.ctaLabel}|${INTERNAL_CTA_TYPE}`}
              logoIcon=""
            />
          );
        })}
        {offerCardResult?.length > 3 && !isPreview && (
          <div className="show-more-button-center-align">
            <div className="show-more-section">
              <Button
                callback={(): void => showMoreToggle()}
                custom_click_track_value={`${model?.id}|${
                  showMoreDetails === DETAILS_HIDDEN ? model?.showMore : model?.showless
                }|${INTERNAL_CTA_TYPE}`}
                className={clsx(`offer-card-show-more-btn m-button-secondary`)}
                trackingProperties={trackingProperties}
                ref={OffersCardListRef}
              >
                {showMoreDetails === DETAILS_HIDDEN ? model?.showMore : model?.showless}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CardList;
