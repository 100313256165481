// Styles for AccuWeather go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

const $border = '#979797';

export const StyledAccuWeather = styled.div`
  ${rtl`
  .accu-weather {
    &__container {
      display: none;
      position: absolute;
      top: ${toRem(-55)};
      right: ${toRem(14)};
      border: ${toRem(1)} solid transparent;

      @media ${baseVariables.mediaQuery.md} {
        top: 0;
        right: 0;
      }

      &__wrapper {
        cursor: pointer;
        font-weight: 400;
        padding: ${toRem(12)} 0;

        &-cell {
          .day-cell {
            padding-top: ${toRem(5)};
            font-weight: bold;
            letter-spacing: ${toRem(1.3)};
            text-transform: uppercase;
          }
          .icon-cell {
            margin-right: ${toRem(8)};
            margin-left: ${toRem(6)};
            @media ${baseVariables.mediaQuery.xl} {
              margin-right: ${toRem(6)};
            }
            line-height: ${toRem(24)};
            span[class^='icon-'] {
              font-size: ${toRem(20)};

              &::before {
                color: ${baseVariables.color.neutral00};
              }
              @media ${baseVariables.mediaQuery.xl} {
                font-size: ${toRem(24)};
              }
            }
          }
          .temperature-cell {
            font-size: ${toRem(14)};
            line-height: ${toRem(16)};
            font-weight: bold;
            letter-spacing: 0;
            @media ${baseVariables.mediaQuery.xl} {
              font-size: ${toRem(20)};
              line-height: ${toRem(24)};
            }
          }
          .unit-cell {
            font-size: ${toRem(12)};
            line-height: ${toRem(15)};
            font-weight: bold;
            letter-spacing: 0;
            color: ${baseVariables.color.base10};
            @media ${baseVariables.mediaQuery.xl} {
              font-size: ${toRem(14)};
              line-height: ${toRem(16)};
            }
          }
        }
      }
      &__logo {
        &_img {
          width: ${toRem(90)};
          margin-top: ${toRem(-4)};
        }
      }
    }
  }
  .accu-weather-forecast {
    &__container {
      position: relative;
      margin-top: ${toRem(60)};
      margin-bottom: ${toRem(60)};
      &__content {
        position: absolute;
        left: 0;
        top: ${toRem(-55)};
        letter-spacing: ${toRem(1.3)};
        text-transform: uppercase;
        @media ${baseVariables.mediaQuery.xl} {
          top: 0;
        }
      }
      &__logo {
        position: absolute;
        right: 0;
        top: ${toRem(-55)};
        @media ${baseVariables.mediaQuery.xl} {
          top: 0;
        }
      }
      &__wrapper {
        cursor: pointer;
        font-weight: 400;
        padding: ${toRem(1)} ${toRem(16)};
        border-left: ${toRem(1)} solid ${$border};

        &:nth-child(2) {
          border-left: none;
        }

        :lang(ar-AE), 
        :lang(ar) {
        &:nth-child(2) {
          border-left: ${toRem(1)} solid ${$border};
        }
          &:nth-child(1) {
          border-left: none;
        }
      }

        &:nth-child(5),
        &:nth-child(6) {
          display: none;
          @media ${baseVariables.mediaQuery.xl} {
            display: block;
          }
        }
      }
    }
  }
  .accu-weather__container__wrapper-cell {
    .forecast-day-cell {
      padding-top: ${toRem(5)};
      font-weight: bold;
      letter-spacing: ${toRem(1.3)};
      text-transform: uppercase;
      position: absolute;
      top: ${toRem(-27)};
      margin-left: ${toRem(20)};
      &-cell {
        font-weight: bold;
      }

      @media ${baseVariables.mediaQuery.xl} {
        position: relative;
        top: unset;
        margin-left: unset;
      }
    }
    .forecast-temperature-cell {
      font-size: ${toRem(20)};
      line-height: ${toRem(24)};
      padding-right: ${toRem(2)};
      font-weight: bold;
      letter-spacing: 0;
    }
    .forecast-unit-cell {
      font-size: ${toRem(14)};
      line-height: ${toRem(16)};
      font-weight: bold;
      letter-spacing: 0;
      color: ${baseVariables.color.base10};
    }
  }
  `}
`;
