/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Button } from '@marriott/mi-ui-library';
import { PageParamsContext } from '../../../../modules/context/PageContext';
import { ButtonTypeVariation } from '../../../../modules/utils/enums/enums';
import { Constants, DEFAULT_LOCALE, LOCALE_SUPPORT_NO_SPACE_C_PRICE_MODAL } from '../../utils/constant';
import { handleDurationDropdownKeyDownEvent, submitDynamicForm } from '../../utils/handler';
import { formatCurrency } from '../../../../modules/utils/common';
import { InternetBarPricingModalProps } from './InternetBarModal.types';
import { InternetBarPricingProps } from '../../InternetBar.types';

export const InternetBarPricingModal: FC<InternetBarPricingModalProps> = (props: InternetBarPricingModalProps) => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const { experienceSegment = '', state = '', locale } = preProcessorResponse || {};
  const {
    internetPricingModel,
    showDropdownFirstTier,
    showDropdownSecondTier,
    setShowDropdownFirstTier,
    setShowDropdownSecondTier,
    dropdownRefFirst,
    dropdownRefSecond,
  } = props;
  const { pricingInfo } = internetPricingModel as InternetBarPricingProps;
  const pricingConnectNowButtonRef = useRef(null);
  const pricingConnectNowButtonRefTwo = useRef(null);
  const pricingDurationDropdownRef = useRef(null);
  const pricingDurationDropdownRefTwo = useRef(null);
  const dropdownListOne = useRef(null);
  const dropdownListTwo = useRef(null);
  const [connectOneBtnDisable, setConnectOneBtnDisable] = useState(false);
  const [connectTwoBtnDisable, setConnectTwoBtnDisable] = useState(false);

  const {
    APS = undefined,
    DD = '',
    DOMAIN = '',
    DURATION = '',
    LR = '',
    MARSHA = '',
    MS,
    Z = '',
    usageAttr = '',
    zoneMapping = '',
    postEndpoint = '',
  } = props?.internetBarDataValues || {};

  const durationDropdownSpacingIssue: boolean = LOCALE_SUPPORT_NO_SPACE_C_PRICE_MODAL?.includes(locale?.toLowerCase());

  const MAX_DURATION = 31;

  type IDuration = {
    key: string;
    value: string;
  };

  const durations: IDuration[] = [];

  for (let i = 0; i < MAX_DURATION; i++) {
    if (i) {
      durations.push({
        key: `${i + 1}-days`,
        value: durationDropdownSpacingIssue
          ? `${i + 1}${props?.internetPricingModel?.justDaysLabel}`
          : `${i + 1} ${props?.internetPricingModel?.justDaysLabel}`,
      });
    } else {
      durations.push({
        key: `${i + 1}-day`,
        value: durationDropdownSpacingIssue
          ? `${i + 1}${props?.internetPricingModel?.justDayLabel}`
          : `${i + 1} ${props?.internetPricingModel?.justDayLabel}`,
      });
    }
  }

  const [durationOne, setDurationOne] = useState(
    DD && parseInt(DD) ? durations[parseInt(DD) - 1].value : durations[0].value
  );
  const [durationTwo, setDurationTwo] = useState(
    DD && parseInt(DD) ? durations[parseInt(DD) - 1].value : durations[0].value
  );
  const [dataIsFirstTier, setDataIsFirstTier] = useState<boolean>(false);

  const pricingModalLabels = [
    {
      title: 'internetPricingTitle',
      generalDescription: 'internetPricingDescription',
      resortDescription: 'resortDescription',
      destinationDescription: 'destinationDescription',
      buttonLabel: 'internetPricingConnectNowLabel',
      plan: 'PLAN1',
      destinationDescriptionPP: 'destinationDescriptionPP',
      resortDescriptionPP: 'resortDescriptionPP',
    },
    {
      title: 'title2',
      generalDescription: 'description2',
      resortDescription: 'resortDescription2',
      destinationDescription: 'destinationDescription2',
      buttonLabel: 'connectNowLabel2',
      plan: 'PLAN2',
    },
  ];

  let requiredPricingInfo = { ...pricingInfo?.['GuestRoom'] };

  if (Z === 'PUBLIC' || Z === 'LOBBY' || Z === 'CONFERENCE' || Z === 'MEETING') {
    requiredPricingInfo = { ...pricingInfo?.['PublicArea'] };
  } else {
    requiredPricingInfo = { ...pricingInfo?.['GuestRoom'] };
  }

  if (
    experienceSegment?.toLowerCase()?.includes('basic') ||
    (experienceSegment?.toLowerCase()?.includes('member') && !experienceSegment?.toLowerCase()?.includes('elite')) ||
    (state.toLowerCase() === 'upgrade' &&
      (experienceSegment?.toLowerCase()?.includes('basic') ||
        (experienceSegment?.toLowerCase()?.includes('member') &&
          !experienceSegment?.toLowerCase()?.includes('elite')) ||
        experienceSegment?.toLowerCase()?.includes('unknown')))
  ) {
    if (
      parseFloat(requiredPricingInfo?.tierTwoFee) &&
      parseFloat(requiredPricingInfo?.tierOneFee) &&
      parseFloat(requiredPricingInfo?.tierTwoFee) > parseFloat(requiredPricingInfo?.tierOneFee) &&
      Z === 'GUEST'
    ) {
      requiredPricingInfo.tierTwoFee = (
        parseFloat(requiredPricingInfo?.tierTwoFee) - parseFloat(requiredPricingInfo?.tierOneFee)
      ).toFixed(2);

      requiredPricingInfo.tierOneFee = '0';
    }
    requiredPricingInfo.tierOneFee = '0';

    if (parseFloat(requiredPricingInfo?.tierTwoFee) < parseFloat(requiredPricingInfo?.tierOneFee)) {
      requiredPricingInfo.tierOneFee = '0';
      requiredPricingInfo.tierTwoFee = '0';
    }
  } else if (experienceSegment?.toLowerCase()?.includes('elite')) {
    requiredPricingInfo.tierOneFee = '0';
    requiredPricingInfo.tierTwoFee = '0';
  }

  const updatedPricingModalLabels: any[] = [];
  if (requiredPricingInfo?.hasTierOne) {
    updatedPricingModalLabels.push({
      ...pricingModalLabels[0],
      tierName: requiredPricingInfo?.tierOneName ?? '',
      tierId: requiredPricingInfo?.tierOneId,
      tierFee: requiredPricingInfo?.tierOneFee ?? undefined,
      isFree:
        requiredPricingInfo?.tierOneFee === undefined || parseFloat(requiredPricingInfo?.tierOneFee).toFixed(0) === '0',
    });
  }

  if (requiredPricingInfo?.hasTierTwo) {
    updatedPricingModalLabels.push({
      ...pricingModalLabels[1],
      tierName: requiredPricingInfo?.tierTwoName ?? '',
      tierId: requiredPricingInfo?.tierTwoId,
      tierFee: requiredPricingInfo?.tierTwoFee ?? undefined,
      isFree:
        requiredPricingInfo?.tierTwoFee === undefined || parseFloat(requiredPricingInfo?.tierTwoFee).toFixed(0) === '0',
    });
  }

  useEffect(() => {
    let isTier1 = false;
    if (requiredPricingInfo?.hasTierOne === true && requiredPricingInfo?.hasTierTwo === false) {
      isTier1 = true;
    } else if (requiredPricingInfo?.hasTierOne === true && requiredPricingInfo?.hasTierTwo === true) {
      isTier1 = false;
    }
    setDataIsFirstTier(isTier1);
  }, [requiredPricingInfo]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (pricingDurationDropdownRef && pricingDurationDropdownRef?.current) {
        (pricingDurationDropdownRef?.current as HTMLInputElement)?.focus();
      } else if (pricingConnectNowButtonRef && pricingConnectNowButtonRef?.current) {
        (pricingConnectNowButtonRef?.current as HTMLInputElement)?.focus();
      }
    }, 20);
    return () => clearTimeout(timeout);
  }, []);

  const tierConnect = (evt: any) => {
    const dataSPValue = evt.target.getAttribute('data-sp-value');
    let tierDurationValue = DURATION;
    if (dataSPValue === 'PLAN1') {
      setConnectOneBtnDisable(true);
      tierDurationValue =
        updatedPricingModalLabels[0]?.tierFee === undefined || updatedPricingModalLabels[0].tierFee === '0'
          ? DURATION
          : durationOne?.split(' ')?.[0];
    } else {
      setConnectTwoBtnDisable(true);
      tierDurationValue =
        updatedPricingModalLabels[1]?.tierFee === undefined || updatedPricingModalLabels[1].tierFee === '0'
          ? DURATION
          : durationTwo?.split(' ')?.[0];
    }
    const isFirstTier = dataIsFirstTier;
    const currency = internetPricingModel?.currency ?? '';
    const price = dataSPValue === 'PLAN1' ? updatedPricingModalLabels[0].tierFee : updatedPricingModalLabels[1].tierFee;
    let spValue = evt?.target?.getAttribute('data-sp-value') ?? Constants.plan.PLAN1;
    let usageValue = Constants.usageAEM[usageAttr] ?? Constants.usage.PURCHASED;
    const pricing = usageAttr === Constants.usage.MEMBER && zoneMapping[Z ?? 'GUEST'] === 'PP' ? true : false;

    // //added condition for tier1 and no price applicable and not a member
    if (!parseFloat(price) && isFirstTier && !usageAttr && !pricing) {
      usageValue = Constants.usage.FREE;
    }

    // // if usageAttr is elite and  tier2
    if (!isFirstTier && usageAttr === Constants.usage.ELITE && parseFloat(price)) {
      spValue = Constants.plan.PLAN2;
    }

    // // if usage is member & connect button clicked in PLAN2. payload to send as PURCHASED
    if (usageAttr === Constants.usage.MEMBER && spValue === Constants.plan.PLAN2) {
      usageValue = Constants.usage.PURCHASED;
    }

    if (!parseFloat(price) && !isFirstTier && spValue === Constants.plan.PLAN1) {
      if (pricing) {
        usageValue = Constants.usage.MEMBER;
      } else {
        usageValue = Constants.usage.FREE;
      }
    }

    if (postEndpoint && tierDurationValue) {
      try {
        const obj = {
          MARSHA: MARSHA ? MARSHA : undefined,
          LR: LR,
          Z: Z,
          ACTION: 'CONNECTED',
          DOMAIN: DOMAIN ? DOMAIN : undefined,
          MS: MS || MS === '' ? MS : undefined,
          APS: APS,
          USAGE: usageValue,
          SP: spValue,
          DURATION: tierDurationValue ? tierDurationValue : undefined,
          CURRENCY: price && price !== '0' ? (currency ? currency : undefined) : undefined,
          PRICE: price && price !== '0' ? price : undefined,
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, postEndpoint);
      } catch (error) {
        // ignore
      }
    }
  };

  const handleListKeyPress = (event: KeyboardEvent, itemValue: string, updatedPricingModalIndex: number) => {
    if (event?.code?.toLowerCase() === 'enter' || event?.code?.toLowerCase() === 'space') {
      event?.stopPropagation();
      if (!updatedPricingModalIndex) {
        setDurationOne(itemValue);
        setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(false);

        setTimeout(() => {
          if (pricingDurationDropdownRef && pricingDurationDropdownRef?.current) {
            (pricingDurationDropdownRef?.current as HTMLButtonElement)?.focus();
          }
        }, 20);
      } else {
        setDurationTwo(itemValue);
        setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(false);

        setTimeout(() => {
          if (pricingDurationDropdownRefTwo && pricingDurationDropdownRefTwo?.current) {
            (pricingDurationDropdownRefTwo?.current as HTMLButtonElement)?.focus();
          }
        }, 20);
      }
    } else {
      if (!updatedPricingModalIndex) {
        handleDurationDropdownKeyDownEvent(event as unknown as KeyboardEvent, dropdownListOne, durations, {
          value: itemValue,
          key: itemValue,
        });
      } else {
        handleDurationDropdownKeyDownEvent(event as unknown as KeyboardEvent, dropdownListTwo, durations, {
          value: itemValue,
          key: itemValue,
        });
      }
    }
  };

  const setFocusToCurrentValue = (dropdownList: HTMLElement, duration: string) => {
    if (dropdownList) {
      const list = (dropdownList as HTMLElement)?.children;
      const dIndex = durations.findIndex(d => d.value === duration);
      const dValue = list[dIndex];
      (dValue as HTMLElement)?.focus();
    }
  };

  const handleDurationDropdownClick = (e: any, updatedPricingModalIndex: number) => {
    if (e.screenX === 0 && e.screenY === 0) {
      if (!updatedPricingModalIndex) {
        if (showDropdownFirstTier) {
          setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(false);
        } else {
          setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(true);
          setTimeout(() => {
            if (dropdownListOne && dropdownListOne?.current) {
              setFocusToCurrentValue(dropdownListOne?.current, durationOne);
            }
          }, 20);
        }
      } else {
        if (showDropdownSecondTier) {
          setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(false);
        } else {
          setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(true);
          setTimeout(() => {
            if (dropdownListTwo && dropdownListTwo?.current) {
              setFocusToCurrentValue(dropdownListTwo?.current, durationTwo);
            }
          }, 20);
        }
      }
    } else {
      if (!updatedPricingModalIndex) {
        showDropdownFirstTier
          ? setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(false)
          : setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(true);
      } else {
        showDropdownSecondTier
          ? setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(false)
          : setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(true);
      }
    }
  };

  const getPricingModal = (pricingProps: any, updatedPricingModalIndex: number, tierLength: number = 1) => {
    return (
      <div
        className={clsx(
          `internet-bar-component ${
            (pricingProps?.isFree && updatedPricingModalLabels?.length === 1) ||
            !(requiredPricingInfo?.hasTierOne && requiredPricingInfo?.hasTierTwo)
              ? 'my-3 px-2'
              : 'px-xl-0 my-3 my-xl-0 px-2'
          }`
        )}
      >
        <div className="internet-bar-component__body d-flex flex-column justify-content-between">
          <div
            className={`'d-flex flex-column internet-bar-component__header internet-bar-component flex-grow-1 ${
              pricingProps?.isFree || !(requiredPricingInfo?.hasTierOne && requiredPricingInfo?.hasTierTwo)
                ? updatedPricingModalLabels?.length === 2 &&
                  (updatedPricingModalLabels?.[0]?.isFree || updatedPricingModalLabels?.[1]?.isFree)
                  ? 'mt-xl-4 internet-bar-component__header--free'
                  : 'mb-xl-0'
                : 'mb-xl-0 mt-xl-4'
            }`}
          >
            <span className="t-label-s py-2 d-block text-center internet-bar-component__title">
              {internetPricingModel?.[pricingProps?.title as keyof InternetBarPricingProps]}
            </span>
            <div className="d-flex flex-column text-center align-items-center">
              {pricingProps?.isFree ? (
                <div className="d-flex flex-column align-items-center">
                  <span
                    className={clsx(
                      `t-font-m modal-label ${
                        pricingProps?.isFree
                          ? updatedPricingModalLabels?.length === 2 &&
                            (updatedPricingModalLabels?.[0]?.isFree || updatedPricingModalLabels?.[1]?.isFree)
                            ? 'pt-2 pt-xl-4 my-1 mt-xl-0'
                            : 'pt-2 my-1'
                          : 'pt-2 pt-xl-4 my-1 mt-xl-0'
                      }`
                    )}
                  >
                    {internetPricingModel?.freeLabel}
                  </span>
                  <span className="internet-bar-component-seperator-line t-accent-color mt-2"></span>
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center">
                  <span
                    className={clsx(
                      `t-font-m modal-label ${
                        requiredPricingInfo?.hasTierOne && requiredPricingInfo?.hasTierTwo
                          ? 'pt-2 pt-xl-4 my-1 mt-xl-0'
                          : 'pt-2 my-1'
                      }`
                    )}
                  >
                    {formatCurrency(
                      parseFloat(pricingProps?.tierFee).toString()?.includes('.')
                        ? parseFloat(pricingProps?.tierFee)
                        : parseFloat(parseFloat(pricingProps?.tierFee).toFixed(2)),
                      locale ?? DEFAULT_LOCALE,
                      internetPricingModel?.currency
                    )}
                  </span>
                  <span className="t-font-xs pt-2">
                    {internetPricingModel?.currency}
                    {internetPricingModel?.dayLabel}
                  </span>
                  <span className="internet-bar-component-seperator-line t-accent-color mt-2"></span>
                </div>
              )}
              <div className="d-flex flex-column">
                {pricingProps?.isFree ? (
                  <span
                    className={clsx(
                      `t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc general-description`
                    )}
                  >
                    {dataIsFirstTier
                      ? internetPricingModel?.internetPricingDescription
                      : internetPricingModel?.freeDescription}
                  </span>
                ) : (
                  <>
                    {DD?.toUpperCase() !== 'R' && DD?.toUpperCase() !== 'D' && (
                      <span
                        className={clsx(
                          `t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc general-description`
                        )}
                      >
                        {internetPricingModel?.[pricingProps?.generalDescription as keyof InternetBarPricingProps]}
                      </span>
                    )}

                    {DD?.toUpperCase() === 'R' &&
                      (tierLength === 2 && pricingProps?.plan === 'PLAN1' ? (
                        <span className="t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc resort-description">
                          {internetPricingModel?.resortDescriptionPP}
                        </span>
                      ) : (
                        <span className="t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc resort-description">
                          {internetPricingModel?.[pricingProps?.resortDescription as keyof InternetBarPricingProps]}
                        </span>
                      ))}

                    {DD?.toUpperCase() === 'D' &&
                      (tierLength === 2 && pricingProps?.plan === 'PLAN1' ? (
                        <span className="t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc desctination-description">
                          {internetPricingModel?.destinationDescriptionPP}
                        </span>
                      ) : (
                        <span className="t-overline-normal pt-2 pb-3 px-3 mx-2 mx-md-0 internet-bar-component-desc modal-price-desc desctination-description">
                          {
                            internetPricingModel?.[
                              pricingProps?.destinationDescription as keyof InternetBarPricingProps
                            ]
                          }
                        </span>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
          {!pricingProps?.isFree && (
            <div
              className={clsx(
                `m-form-group ${
                  requiredPricingInfo?.hasTierOne && requiredPricingInfo?.hasTierTwo
                    ? updatedPricingModalLabels?.length === 2
                      ? 'py-5 my-3 mb-0'
                      : 'py-5 my-3 mb-0 pb-xl-3 mb-xl-4'
                    : 'py-5 my-3 mb-0'
                }`
              )}
            >
              <div className="t-font-s mb-2">{internetPricingModel?.choosedurationlabel}</div>
              <div ref={!updatedPricingModalIndex ? dropdownRefFirst : dropdownRefSecond}>
                <Button
                  isLink={false}
                  type={ButtonTypeVariation?.Button}
                  className={clsx(
                    `d-flex justify-content-between pt-0 pb-2 px-0 internetbarmodal-${props?.modalType} internetbarmodal-${props?.modalType}__dropdownbtn`
                  )}
                  callback={e => {
                    setConnectOneBtnDisable(false);
                    setConnectTwoBtnDisable(false);
                    handleDurationDropdownClick(e, updatedPricingModalIndex);
                  }}
                  ref={pricingProps?.plan === 'PLAN1' ? pricingDurationDropdownRef : pricingDurationDropdownRefTwo}
                  children={
                    <>
                      <span className="t-font-m pb-1" data-testid="duration-value">
                        {!updatedPricingModalIndex ? durationOne : durationTwo}
                      </span>
                      <span
                        className={clsx(
                          showDropdownFirstTier || showDropdownSecondTier ? 'icon-arrow-down' : 'icon-arrow-up'
                        )}
                      ></span>
                    </>
                  }
                  testId={!updatedPricingModalIndex ? 'tier-one-dropdown' : 'tier-two-dropdown'}
                />
                {(!updatedPricingModalIndex ? showDropdownFirstTier : showDropdownSecondTier) && (
                  <ul
                    data-testid="duration-dropdown"
                    className={clsx(
                      `mt-3 p-0 mb-0 internetbarmodal-${props?.modalType} internetbarmodal-${props?.modalType}__dropdownlist`
                    )}
                    ref={!updatedPricingModalIndex ? dropdownListOne : dropdownListTwo}
                  >
                    {durations.map(item => {
                      return (
                        <li
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                          role={'button'}
                          key={item.key}
                          className={`pt-3 pb-3 d-flex justify-content-between align-items-center internetbarmodal-${props?.modalType} internetbarmodal-${props?.modalType}__dropdownitems`}
                          tabIndex={0}
                          onClick={event => {
                            event.stopPropagation();
                            if (!updatedPricingModalIndex) {
                              setDurationOne(item.value);
                              setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(false);
                            } else {
                              setDurationTwo(item.value);
                              setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(false);
                            }
                          }}
                          onKeyDown={(event: KeyboardEvent | any) =>
                            handleListKeyPress(event, item.value, updatedPricingModalIndex)
                          }
                        >
                          <span className="value" data-code={item.value}>
                            {item.value}
                          </span>
                          <span className="icon-forward-arrow arrow ml-2 pr-3"></span>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          )}
          <button
            className={clsx(
              `m-button m-button-m m-button-primary t-font-m internet-bar-component-button w-100 ${
                requiredPricingInfo?.hasTierOne && requiredPricingInfo?.hasTierTwo && !pricingProps?.isFree
                  ? 'mb-3'
                  : pricingProps?.isFree
                  ? updatedPricingModalLabels?.length === 2 &&
                    (updatedPricingModalLabels?.[0]?.isFree || updatedPricingModalLabels?.[1]?.isFree)
                    ? 'mb-3 mt-5 mt-xl-0'
                    : 'mt-4'
                  : ''
              } ${
                (pricingProps?.plan === 'PLAN1' && connectOneBtnDisable === true) ||
                (pricingProps?.plan === 'PLAN2' && connectTwoBtnDisable)
                  ? 'disabled'
                  : ''
              } `
            )}
            role={'button'}
            ref={pricingProps?.plan === 'PLAN1' ? pricingConnectNowButtonRef : pricingConnectNowButtonRefTwo}
            data-sp-value={pricingProps?.plan}
            onClick={e => tierConnect(e)}
            onFocus={() => {
              if (!updatedPricingModalIndex) {
                setShowDropdownFirstTier !== undefined && setShowDropdownFirstTier(false);
              } else {
                setShowDropdownSecondTier !== undefined && setShowDropdownSecondTier(false);
              }
            }}
            disabled={
              (pricingProps?.plan === 'PLAN1' && connectOneBtnDisable === true) ||
              (pricingProps?.plan === 'PLAN2' && connectTwoBtnDisable)
                ? true
                : false
            }
            data-testid={pricingProps?.plan === 'PLAN1' ? 'connect-one-btn' : 'connect-two-btn'}
          >
            {(pricingProps?.plan === 'PLAN1' && connectOneBtnDisable === true) ||
            (pricingProps?.plan === 'PLAN2' && connectTwoBtnDisable) ? (
              <div className="m-spinner-s" data-testid="activate-spinner"></div>
            ) : (
              internetPricingModel?.[pricingProps?.buttonLabel as keyof InternetBarPricingProps]
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      className={clsx(
        updatedPricingModalLabels?.length === 1 || !(requiredPricingInfo?.hasTierOne && requiredPricingInfo?.hasTierTwo)
          ? 'px-4 py-3 d-flex flex-column'
          : 'px-4 py-3 pb-xl-4 pt-xl-0 d-flex flex-column'
      )}
    >
      {updatedPricingModalLabels && updatedPricingModalLabels?.length
        ? updatedPricingModalLabels.map((updatedPricingModal, updatedPricingModalIndex) =>
            getPricingModal(updatedPricingModal, updatedPricingModalIndex, updatedPricingModalLabels?.length)
          )
        : null}
    </div>
  );
};
