// Styles for ExploreDestinations go here.
import { toRem } from '@marriott/mi-ui-library';
import { theme } from '@marriott/mi-ui-library-shop';
import styled from 'styled-components';

export const StyledExploreDestinations = styled.div`
  .explore-destination-header {
    display: flex;
    height: ${toRem(28)};
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    font-size: ${toRem(22)};
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 27.5px */
  }
  .destination-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: ${toRem(32)};
  }
  .destination-content {
    display: flex;
    width: ${toRem(348)};
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 16px 16px 16px 0;
    border-top: ${toRem(1)} solid ${theme.color['light-gray-3']};
    @media (min-width: 1023px) and (max-width: 1199px) {
      width: ${toRem(288)};
    }
    @media (min-width: 820px) and (max-width: 899px) {
      width: ${toRem(208)};
    }
    @media (min-width: 768px) and (max-width: 810px) {
      width: ${toRem(208)};
    }
  }
  .destination-card:nth-last-child(-n + 3) {
    .destination-content {
      border-bottom: ${toRem(1)} solid ${theme.color['light-gray-3']};
    }
  }
  .destination-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 700;
    line-height: ${toRem(20)};
  }
  .destination-badge {
    display: flex;
    height: ${toRem(17)};
    padding: 0px var(--General-spacer-01, 4px);
    justify-content: center;
    align-items: center;
    border-radius: ${toRem(4)};
  }
  .cta-icon {
    font-weight: 700;
  }
`;
