/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject } from 'react';
import { Constants, LOYALTY_MR_ENROLLMENT_SOURCE, LOYALTY_ENROLLMENT_SOURCE, DEFAULT_LOYALTY_OBJ } from './constant';
import { IDuration } from '../components/InternetBarModal/InternetBarModal.types';
import DOMPurify from 'isomorphic-dompurify';

export const submitDynamicForm = (body: any, postEndpoint: string): void => {
  const form = document.createElement('form');
  const parsedBody = JSON.parse(body);
  const submitFormEndpoint = postEndpoint.indexOf('://') === -1 ? `https://${postEndpoint}` : postEndpoint;
  form.setAttribute('method', 'POST');
  form.setAttribute('action', submitFormEndpoint);
  form.setAttribute('style', 'display: none');
  DOMPurify.sanitize(form, { RETURN_DOM_FRAGMENT: true });

  for (const property in parsedBody) {
    if (parsedBody[property] !== null) {
      const input = document.createElement('input');
      input.type = 'text';
      input.id = property;
      input.name = property;
      input.value = parsedBody[property];
      form.appendChild(input);
    }
  }

  // add the form inside the body
  document.getElementsByTagName('body')[0].appendChild(form); //pure javascript
  form.submit();
};

// @todo :: Move noLoginInterfaceModal Logic here
// const handleConnectNoLogin = () => {}

export const connectBtnTrigger = (
  isNoLoginInterfaceModal: boolean,
  internetBarDataValues: Record<string, any>
): void => {
  if (internetBarDataValues && internetBarDataValues?.['postEndpoint']) {
    if (isNoLoginInterfaceModal) {
      try {
        const zoneMapping = internetBarDataValues?.['zoneMapping'];
        const Z = internetBarDataValues?.['Z'] ?? '';
        let usage = internetBarDataValues?.['USAGE'] ?? '';
        const usageAttr = internetBarDataValues?.['usageAttr'] ?? '';
        const LR = internetBarDataValues?.['LR'] ?? '';
        const aemUSAGE = Constants.usageAEM[usageAttr] ? usageAttr : Constants.usage.FREE;

        const SP =
          usageAttr === Constants.usage.ELITE &&
          (zoneMapping[Z ?? 'GUEST'] === 'FP' || zoneMapping[Z ?? 'GUEST'] === 'PP')
            ? Constants.plan.PLAN2
            : Constants.plan.PLAN1;

        const sp = SP ?? '';

        if (
          sp === 'PLAN1' &&
          (zoneMapping[Z ?? 'PUBLIC'] === 'SF' ||
            zoneMapping[Z ?? 'GUEST'] === 'SF' ||
            zoneMapping[Z ?? 'MEETING'] === 'SF' ||
            zoneMapping[Z ?? 'CONFERENCE'] === 'SF')
        ) {
          usage = Constants.usage.FREE;
        }

        //newly added for LR TCTC value for FP and PP USAGE should be considered as FREE
        if (LR?.toUpperCase() === 'TCTC' && sp === 'PLAN1' && Z === 'GUEST') {
          usage = Constants.usage.FREE;
        }

        const obj = {
          MARSHA: internetBarDataValues?.['MARSHA'] ? internetBarDataValues?.['MARSHA'] : undefined,
          LR,
          Z,
          ACTION: 'CONNECTED',
          USAGE: usage ? usage : aemUSAGE,
          SP: sp,
          DURATION: internetBarDataValues?.['DURATION'] ? internetBarDataValues?.['DURATION'] : undefined,
          DOMAIN: internetBarDataValues?.['DOMAIN'] ? internetBarDataValues?.['DOMAIN'] : undefined,
          MS:
            internetBarDataValues?.['MS'] || internetBarDataValues?.['MS'] === ''
              ? internetBarDataValues?.['MS']
              : undefined,
          APS: internetBarDataValues?.['APS'],
          TIERS: zoneMapping[Z ?? ''] ?? undefined,
        };
        const body = JSON.stringify(obj);
        submitDynamicForm(body, internetBarDataValues?.['postEndpoint'] ?? '');
      } catch (error) {
        console.log('Error in connectBtnTrigger handler', error);
      }
    }
  }
};

export const getEnrolledUserFlag = (loyaltyEnrollmentSourceCode: LOYALTY_ENROLLMENT_SOURCE): boolean => {
  const dataLayer = window?.dataLayer ?? {
    ...DEFAULT_LOYALTY_OBJ,
  };
  const sessionData = (window?.sessionData &&
    window?.sessionData?.cacheData &&
    window?.sessionData?.cacheData?.data &&
    window?.sessionData?.cacheData?.data?.AriesRewards) ?? {
    ...DEFAULT_LOYALTY_OBJ,
  };
  if (dataLayer && sessionData) {
    return (dataLayer?.['mr_enrolled'] &&
      LOYALTY_MR_ENROLLMENT_SOURCE?.includes(dataLayer?.['mr_enrollment_source'] as string) &&
      dataLayer?.['mr_enrollment_source'] === loyaltyEnrollmentSourceCode) ||
      (sessionData?.['mr_enrolled'] &&
        LOYALTY_MR_ENROLLMENT_SOURCE?.includes(sessionData?.['mr_enrollment_source'] as string) &&
        sessionData?.['mr_enrollment_source'] === loyaltyEnrollmentSourceCode)
      ? true
      : false;
  }
  return false;
};

export const handleDurationDropdownKeyDownEvent = (
  event: KeyboardEvent,
  dropdownListRef: MutableRefObject<null>,
  durations: IDuration[] = [],
  item: IDuration
) => {
  if (event?.code?.toLowerCase() === 'arrowdown') {
    event?.stopPropagation();
    setTimeout(() => {
      const dropdownList = dropdownListRef?.current;
      if (dropdownList) {
        const list = (dropdownList as HTMLElement)?.children;
        const dIndex = durations?.findIndex(d => d.value === item.value);
        let dValue: Element | null = null;
        if (dIndex < list?.length - 1) {
          dValue = list[dIndex + 1];
        } else {
          dValue = list[0];
        }
        (dValue as HTMLElement)?.focus();
      }
    }, 20);
  } else if (event?.code?.toLowerCase() === 'arrowup') {
    event?.stopPropagation();
    setTimeout(() => {
      const dropdownList = dropdownListRef?.current;
      if (dropdownList) {
        const list = (dropdownList as HTMLElement)?.children;
        const dIndex = durations?.findIndex(d => d.value === item.value);
        let dValue: Element | null = null;
        if (dIndex - 1 >= 0) {
          dValue = list[dIndex - 1];
        } else {
          dValue = list[list?.length - 1] ?? list[0];
        }
        (dValue as HTMLElement)?.focus();
      }
    }, 20);
  } else {
    event?.stopPropagation();
  }
};
