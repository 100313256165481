// Imports for external libraries go here.
import { FC, useState, useRef } from 'react';
import { AccessModal, LoginModal, RequestAccessModal } from '../../InternetBar.types';
import { Ppv5Modal } from '../../../Ppv5Modal';
import { useServiceStore, serviceStore } from '../../../../modules/store/serviceStore';
import { PricingInfoProps } from '../../InternetBar.types';
import { InternetBarLoginModal } from './InternetBarLoginModal';
import { InternetBarAccessModal } from './InternetBarAccessModal';
import { InternetBarReqAccessModal } from './InternetBarReqAccessModal';
import { InternetBarErrorModal } from './InternetBarErrorModal';
import { InternetBarPricingModal } from './InternetBarPricingModal';
import { InternetBarUpgradeModal } from './InternetBarUpgradeModal';
import { InternetBarLoyaltyWelcomeModal } from './InternetBarLoyaltyWelcomeModal';
import { InternetBarModalProps } from './InternetBarModal.types';
import { StyledInternetBarModal, StyledNoErrorBody } from './InternetBarModal.styles';

export const InternetBarModal: FC<InternetBarModalProps> = (props: InternetBarModalProps) => {
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);
  const [showModal, setShowModal] = useState<boolean>(props?.showInternetBarModal);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showDropdownFirstTier, setShowDropdownFirstTier] = useState<boolean>(false);
  const [showDropdownSecondTier, setShowDropdownSecondTier] = useState<boolean>(false);

  const { pricingInfo } = (props && props?.internetPricingModel) || {};

  let requiredPricingInfo: PricingInfoProps = { ...pricingInfo?.['GuestRoom'] };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRefFirst = useRef<HTMLDivElement>(null);
  const dropdownRefSecond = useRef<HTMLDivElement>(null);

  const { ERR = '', Z = '' } = props?.internetBarDataValues || {};

  const getNoErrorBody = (message: string, description: string) => {
    return (
      <StyledNoErrorBody>
        <div className="body-container d-flex flex-column ">
          {/* 1.1 Internet Bar Upgrade No Error Modal */}
          <div className="d-flex">
            <span className={`col-12 px-3 py-3 mb-4 d-flex align-items-center noerrorbody__container`}>
              {' '}
              <span className="icon-information mr-3"></span>
              <span className="t-font-s"> {message}</span>
            </span>
          </div>
          <span className="t-font-xs pt-2">{description}</span>
        </div>
      </StyledNoErrorBody>
    );
  };

  const getModalContent = (): JSX.Element | JSX.Element[] => {
    let modalBody: JSX.Element | JSX.Element[] = (
      <div>{/* {Empty Fragment in case modal contenjt is not available} */}</div>
    );
    switch (props?.modalType) {
      case 'LOGIN':
      case 'LOGERR':
        // eslint-disable-next-line no-case-declarations
        const loginModal =
          props?.currentStateMapping &&
          props?.currentStateMapping?.showLoginModal &&
          props?.currentStateMapping?.loginModal;

        if (loginModal) {
          modalBody = (
            <InternetBarLoginModal
              login={props?.login}
              loginModal={props?.currentStateMapping?.loginModal as LoginModal}
              internetBarDataValues={props?.internetBarDataValues}
              loginModalType={props?.modalType}
              currentStateMapping={props?.currentStateMapping}
            />
          );
        }
        break;

      case 'ACCESS':
      case 'ACCESSERR':
        // eslint-disable-next-line no-case-declarations
        const accessModal =
          props?.currentStateMapping &&
          props?.currentStateMapping?.showAccessModal &&
          props?.currentStateMapping?.accessModal;

        if (accessModal) {
          modalBody = (
            <InternetBarAccessModal
              access={props?.accessModal}
              accessModal={props?.currentStateMapping?.accessModal as AccessModal}
              internetBarDataValues={props?.internetBarDataValues}
              accessModalType={props?.modalType}
            />
          );
        }
        break;

      case 'REQSMS':
      case 'REQSMSERR':
        // eslint-disable-next-line no-case-declarations
        const reqAccessModal =
          props?.currentStateMapping &&
          props?.currentStateMapping?.showReqAccessModal &&
          props?.currentStateMapping?.reqAccessModal;

        if (reqAccessModal) {
          modalBody = (
            <InternetBarReqAccessModal
              reqAccess={props?.requestAccessCodeModal}
              reqAccessModal={props?.currentStateMapping?.reqAccessModal as RequestAccessModal}
              internetBarDataValues={props?.internetBarDataValues}
              reqAccessModalType={props?.modalType}
              dropdownRef={props?.modalType === 'REQSMS' || props?.modalType === 'REQSMSERR' ? dropdownRef : undefined}
              setShowDropdown={
                props?.modalType === 'REQSMS' || props?.modalType === 'REQSMSERR' ? setShowDropdown : undefined
              }
              showDropdown={
                props?.modalType === 'REQSMS' || props?.modalType === 'REQSMSERR' ? showDropdown : undefined
              }
            />
          );
        }
        break;

      case 'INT_ERR':
        modalBody = (
          <InternetBarErrorModal
            wifiUpgradeGENError={props?.wifiUpgradeGENError}
            wifiUpgradeLR={props?.wifiUpgradeLR}
            wifiUpgradeMAX={props?.wifiUpgradeMAX}
            wifiUpgradeNoCC={props?.wifiUpgradeNoCC}
            wifiUpgradePMS={props?.wifiUpgradePMS}
            internetBarDataValues={props?.internetBarDataValues}
            currentStateMapping={props?.currentStateMapping}
          />
        );
        break;

      case 'U_MAX':
        if (props?.currentStateMapping?.showUpgradeSuccessMAXInfo && props?.wifiUpgradeSuccessMAX) {
          modalBody = getNoErrorBody(
            props?.wifiUpgradeSuccessMAX?.upgradeMessageSmax,
            props?.wifiUpgradeSuccessMAX?.descriptionSmax
          );
        }
        break;

      case 'U_NA':
        if (props?.currentStateMapping?.showUpgradeSuccessNAInfo && props?.wifiUpgradeSuccessNA) {
          modalBody = getNoErrorBody(
            props?.wifiUpgradeSuccessNA?.upgradeMessageSna,
            props?.wifiUpgradeSuccessNA?.descriptionSna
          );
        }
        break;

      case 'C_PRICE':
        if (props?.currentStateMapping?.showPricingModal && props?.internetPricingModel) {
          modalBody = (
            <InternetBarPricingModal
              internetPricingModel={props?.internetPricingModel}
              internetBarDataValues={props?.internetBarDataValues}
              modalType={props?.modalType}
              dropdownRefFirst={props?.modalType === 'C_PRICE' ? dropdownRefFirst : undefined}
              dropdownRefSecond={props?.modalType === 'C_PRICE' ? dropdownRefSecond : undefined}
              setShowDropdownFirstTier={props?.modalType === 'C_PRICE' ? setShowDropdownFirstTier : undefined}
              setShowDropdownSecondTier={props?.modalType === 'C_PRICE' ? setShowDropdownSecondTier : undefined}
              showDropdownFirstTier={props?.modalType === 'C_PRICE' ? showDropdownFirstTier : undefined}
              showDropdownSecondTier={props?.modalType === 'C_PRICE' ? showDropdownSecondTier : undefined}
            />
          );
        }

        break;

      case 'U_PRICE':
        if (Z === 'PUBLIC' || Z === 'LOBBY' || Z === 'CONFERENCE' || Z === 'MEETING') {
          requiredPricingInfo = { ...pricingInfo['PublicArea'] };
        } else {
          requiredPricingInfo = { ...pricingInfo['GuestRoom'] };
        }

        if (!Object.keys(requiredPricingInfo).length) {
          requiredPricingInfo.hasTierTwo = false;
        }

        if (props?.currentStateMapping?.showUpgradeModal && props?.internetPricingModel) {
          modalBody = (
            <InternetBarUpgradeModal
              internetPricingModel={props?.internetPricingModel}
              internetBarDataValues={props?.internetBarDataValues}
              modalType={props?.modalType}
              dropdownRef={props?.modalType === 'U_PRICE' ? dropdownRef : undefined}
              showModal={showModal}
              setShowDropdown={props?.modalType === 'U_PRICE' ? setShowDropdown : undefined}
              showDropdown={props?.modalType === 'U_PRICE' ? showDropdown : undefined}
              setShowModal={props?.modalType === 'U_PRICE' ? setShowModal : undefined}
            />
          );
        }
        break;

      case 'LOYALTY_WELCOME':
        modalBody = (
          <InternetBarLoyaltyWelcomeModal
            login={props?.login}
            loginModal={props?.currentStateMapping?.loginModal as LoginModal}
            internetBarDataValues={props?.internetBarDataValues}
            loginModalType={props?.modalType}
            currentStateMapping={props?.currentStateMapping}
          />
        );
        break;
    }
    return modalBody;
  };

  const getModalTitle = (): string => {
    let modalTitle = '';
    switch (props?.modalType) {
      case 'LOGIN':
      case 'LOGERR':
      case 'LOYALTY_WELCOME':
        modalTitle = props?.login?.title;
        break;

      case 'ACCESS':
      case 'ACCESSERR':
        modalTitle = props?.accessModal?.acTitle;
        break;

      case 'REQSMS':
      case 'REQSMSERR':
        modalTitle = props?.requestAccessCodeModal?.racTitle;
        break;

      case 'INT_ERR':
        switch (ERR) {
          case 'GEN':
            // wifiUpgradeErrorFieldsBean
            modalTitle = props?.wifiUpgradeGENError?.headingtext;
            break;

          case 'LR':
            // wifiUpgradeLrFieldsBean
            modalTitle = props?.wifiUpgradeLR?.headingtextLr;
            break;

          case 'PMS':
            // wifiUpgradePmsFieldsBean
            modalTitle = props?.wifiUpgradePMS?.headingtextPms;
            break;

          case 'MAX':
            // wifiUpgradeMaxFieldsBean
            modalTitle = props?.wifiUpgradeMAX?.headingtextMax;
            break;

          case 'NOCC':
            // wifiUpgradeCreditFieldsBean
            modalTitle = props?.wifiUpgradeNoCC?.headingtext1;
            break;
        }
        break;

      case 'U_MAX':
        modalTitle = props?.wifiUpgradeSuccessMAX?.headingtextSmax;
        break;

      case 'U_NA':
        modalTitle = props?.wifiUpgradeSuccessNA?.headingtextSna;
        break;

      case 'C_PRICE':
        modalTitle = props?.internetPricingModel?.connectlabelTier1;
        break;

      case 'U_PRICE':
        modalTitle = props?.internetPricingModel?.intUpgConnectLabel;
        break;
    }
    return modalTitle;
  };

  const closeButtonHandler = () => {
    setShowModal(false);
    setServiceData(
      {
        connectBtnClick: false,
      },
      false,
      false,
      {}
    );
    props?.handleCloseBtnClick();
  };

  return (
    <StyledInternetBarModal>
      <Ppv5Modal
        showModal={showModal}
        modalTitle={getModalTitle()}
        diningModal={false}
        accessModal={false}
        errorModal={
          props?.modalType === 'U_NA' || props?.modalType === 'U_MAX' || props?.modalType === 'INT_ERR' ? true : false
        }
        priceModal={props?.modalType === 'C_PRICE' ? true : false}
        upgradeModal={props?.modalType === 'U_PRICE' ? true : false}
        modalContent={getModalContent()}
        closeBtnHandler={() => closeButtonHandler()}
        dropdownRef={
          props?.modalType === 'REQSMS' || props?.modalType === 'REQSMSERR' || props?.modalType === 'U_PRICE'
            ? dropdownRef
            : undefined
        }
        dropdownRefFirst={props?.modalType === 'C_PRICE' ? dropdownRefFirst : undefined}
        dropdownRefSecond={props?.modalType === 'C_PRICE' ? dropdownRefSecond : undefined}
        setShowDropdown={
          props?.modalType === 'REQSMS' || props?.modalType === 'REQSMSERR' || props?.modalType === 'U_PRICE'
            ? setShowDropdown
            : undefined
        }
        setShowDropdownFirstTier={props?.modalType === 'C_PRICE' ? setShowDropdownFirstTier : undefined}
        setShowDropdownSecondTier={props?.modalType === 'C_PRICE' ? setShowDropdownSecondTier : undefined}
      />
    </StyledInternetBarModal>
  );
};
