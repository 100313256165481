export const offersDataMock = {
  destinations: [
    {
      count: 100,
      id: 'Card-1',
      title: 'United States',
      url: '#',
    },
    {
      count: 1000,
      id: 'card-2',
      title: 'Caribbean',
      url: '#2',
    },
    {
      count: 200,
      id: 'Card-3',
      title: 'Middle East & Africa',
      url: '#3',
    },
    {
      count: 2300,
      id: 'card-4',
      title: 'Europe',
      url: '#4',
    },
    {
      count: 10,
      id: 'Card-5',
      title: 'Canada',
      url: '#5',
    },
    {
      count: 785,
      id: 'card-6',
      title: 'Latin America',
      url: '#6',
    },
  ],
};
export const exploreDestinationModelMock = {
  path: '/content/mi-aem-offers-spa/na/en-us/findoffers/jcr:content/root/responsivegrid/exploredestinations',
  componentId: 'onogbiek1x3x5in6',
  ':type': 'mi-aem-offers-spa/components/exploredestinations',
  dataLayer: {
    'exploredestinations-27cf8ed124': {
      '@type': 'mi-aem-offers-spa/components/exploredestinations',
      'repo:modifyDate': '2024-09-12T10:52:23Z',
    },
  },
  id: 'exploredestinations-27cf8ed124',
  trackingProperties: {
    additionalTrackingVariables: '',
    enableScrollingBehavior: false,
    trackingDescription: '',
    impressionTrack: false,
    description: '',
    trackingContentPosition: '',
    atCCeVar48: '',
    rightArrowDesc: '',
    cardLocation: '',
    leftArrowDesc: '',
    clickTrack: false,
    location: '',
    merchandisingCategory: '',
    impressionCount: false,
    trackingTag: '',
    trackingOfferType: '',
  },
  title: 'Explore Destinations',
  wcmMode: 'disabled',
  isAuthor: false,
};
export const ExploreDestinationsMock = {
  model: exploreDestinationModelMock,
  isAuthorMode: false,
  mbeData: offersDataMock,
};
