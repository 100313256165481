/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState, useContext, useRef } from 'react';

import Glide from '@glidejs/glide';
import { useServiceStore, serviceStore } from '../../../modules/store/serviceStore';
import { LocalServicesMenuProps, ServicesMenuProps, restaurantProps } from './LocalServicesMenu.types';
import { PageParamsContext } from '../../../modules/context/PageContext';
import { StyledLocalServicesMenu } from './LocalServicesMenu.styles';
import { serviceIcons } from '../../../modules/utils/constants/constants';
import { Ppv5Modal } from '../../Ppv5Modal/Ppv5Modal';
import { statesConstant, localServiceConstants } from '../../../modules/utils/constants';

export const LocalServicesMenu: FC<LocalServicesMenuProps> = pageProps => {
  const { preProcessorResponse } = useContext(PageParamsContext);
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);
  const [localServicesCount, setLocalServicesCount] = useState(0);
  const [services, setServices] = useState<Array<ServicesMenuProps>>([]);
  const [dinningRestaurants, setDinningRestaurants] = useState<Array<restaurantProps>>([
    { restaurantsTitle: '', restaurantsURL: '' },
  ]);
  const [showDinningModal, setshowDinningModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);

  const [scrollPosition, setScrollPosition] = useState(0);

  const { state = '', cna = false, sameTab = false } = preProcessorResponse || {};
  const firstdiningMenuRef = useRef(null);

  useEffect(() => {
    if (
      state?.toLowerCase() === statesConstant.connected &&
      sessionStorage.getItem('amenityClicked') &&
      !sessionStorage.getItem('reloadConnectedUrl')
    ) {
      setServiceData(
        {
          serviceClicked: true,
        },
        false,
        false,
        {}
      );
    }
  }, []);

  useEffect(() => {
    let service: string | any[] | ((prevState: ServicesMenuProps[]) => ServicesMenuProps[]) = [];
    if (pageProps?.property?.basicInformation?.isOrderFoodAndBeverageByMobileSupported) {
      service = [
        ...service,
        {
          id: localServiceConstants.foodAndBeverage,
          onProperty: true,
          bookNowURL: pageProps?.fnBorderingURL ?? `${pageProps?.foodBeverageCard?.foodBeverageCtaLink}`,
          iconClass: serviceIcons.iconDining,
          title: pageProps?.fnbTitle,
          target: sameTab || cna ? '_self' : '_blank',
        },
      ];
    }
    const spaData = pageProps?.property?.spa?.edges.find(
      edge => edge?.node?.onProperty === true && edge?.node?.bookNowURL
    );
    if (spaData?.node) {
      service = [
        ...service,
        {
          id: localServiceConstants.spa,
          onProperty: spaData?.node?.onProperty,
          bookNowURL: spaData?.node?.bookNowURL,
          iconClass: serviceIcons.iconSpa,
          title: pageProps?.spaTitle,
          target: sameTab || cna ? '_self' : '_blank',
        },
      ];
    }
    if (pageProps?.golfReservationURL) {
      service = [
        ...service,
        {
          id: localServiceConstants.golf,
          onProperty: true,
          bookNowURL: pageProps?.golfReservationURL,
          iconClass: serviceIcons.iconGolf,
          title: pageProps?.golfTitle,
          target: sameTab || cna ? '_self' : '_blank',
        },
      ];
    }
    const dinningData = pageProps?.property?.restaurants?.edges.filter(
      edge => edge?.node?.onProperty === true && edge?.node?.reservationURL
    );
    if (dinningData?.length === 1) {
      service = [
        ...service,
        {
          id: localServiceConstants.dinning,
          onProperty: true,
          bookNowURL: dinningData[0].node?.reservationURL,
          iconClass: serviceIcons.iconDiningChopsticks,
          title: pageProps?.diningTitle,
          target: sameTab || cna ? '_self' : '_blank',
        },
      ];
    } else if (dinningData?.length && dinningData?.length > 1) {
      service = [
        ...service,
        {
          id: localServiceConstants.dinning,
          onProperty: true,
          bookNowURL: '#',
          iconClass: serviceIcons.iconDiningChopsticks,
          title: pageProps?.diningTitle,
        },
      ];
      let restaurants: Array<restaurantProps> = [];
      dinningData?.forEach(restaurant => {
        restaurants = [
          ...restaurants,
          {
            restaurantsTitle: restaurant?.node?.name ?? '',
            restaurantsURL: restaurant?.node?.reservationURL ?? '',
          },
        ];
      });
      setDinningRestaurants(restaurants);
    }

    if (pageProps?.property?.basicInformation) {
      setServices(service);
      setLocalServicesCount(service?.length);
    }
  }, [
    cna,
    pageProps?.diningTitle,
    pageProps?.fnBorderingURL,
    pageProps?.fnbTitle,
    pageProps?.foodBeverageCard?.foodBeverageCtaLink,
    pageProps?.golfReservationURL,
    pageProps?.golfTitle,
    pageProps?.property,
    pageProps?.spaTitle,
    sameTab,
  ]);

  useEffect(() => {
    if (localServicesCount > 3) {
      try {
        new Glide('#localServices', {
          type: 'slider',
          bound: true,
          startAt: 0,
          rewind: false,
          gap: 16,
          keyboard: false,
          autoplay: false,
          hoverpause: true,
          perView: localServicesCount,
          peek: {
            before: 0,
            after: 0,
          },
          breakpoints: {
            1199: {
              perView: localServicesCount,
              peek: {
                before: 0,
                after: 0,
              },
            },
            767: {
              perView: localServicesCount === 4 ? 2 : localServicesCount,
              peek: {
                before: 0,
                after: localServicesCount === 4 ? 80 : 0,
              },
            },
          },
        }).mount({ ArrowDisabler });
      } catch (error) {
        // log.error(`Error in initializing Glide for ${componentId}`, error);
      }
    }
  }, [localServicesCount]);

  const ArrowDisabler = function (Glide: any, Components: any) {
    return {
      mount() {
        // Only in effect when rewinding is disabled
        if (Glide.settings.rewind) {
          return;
        }
        Glide.on(['mount.after', 'run'], () => {
          // Filter out arrows_control
          for (const controlItem of Components.Controls.items) {
            if (controlItem.className !== 'glide__arrows') {
              continue;
            }

            // Set left arrow state
            const left = controlItem?.querySelector('.glide__arrow--left');
            if (left) {
              if (Glide.index === 0) {
                left.classList.add('.is-disabled'); // Disable on first slide
              } else {
                left.classList.remove('.is-disabled'); // Enable on other slides
              }
            }

            // Set right arrow state
            const right = controlItem?.querySelector('.glide__arrow--right');
            if (right) {
              if (Glide.index === Components.Sizes.length - Glide.settings.perView) {
                right.classList.add('.is-disabled'); // Disable on last slide
              } else {
                right.classList.remove('.is-disabled'); // Disable on other slides
              }
            }
          }
        });
      },
    };
  };

  const handleClick = (id: string, bookNowURL = '') => {
    if (id === localServiceConstants.dinning && dinningRestaurants.length > 1) {
      setTimeout(() => {
        setModalBody(
          <div className={dinningRestaurants.length > 4 ? 'd-flex  flex-column flex-md-row' : ''}>
            <div
              className={`local-services-content-options d-flex flex-column col-12 ${
                dinningRestaurants.length > 4 ? 'col-md-6' : ''
              } p-0`}
            >
              {dinningRestaurants?.map((restaurant, index) => {
                if (index < 4) {
                  return (
                    <div className="local-services-content-options-item">
                      <a
                        href={restaurant?.restaurantsURL}
                        className="custom_click_track local-services-content-options-item-link body-list mx-3 py-3 d-flex align-items-center justify-content-between first track-completed"
                        target={sameTab || cna ? '_self' : '_blank'}
                        data-custom_click_track_value={`Service Bar | ${restaurant?.restaurantsTitle?.slice(
                          0,
                          20
                        )} | External`}
                        rel="noreferrer"
                        ref={firstdiningMenuRef}
                        onClick={e => {
                          (state?.toLowerCase() === statesConstant.preconnect ||
                            state?.toLowerCase() === statesConstant.connect) &&
                            e.preventDefault();
                          initLetsGetConnected(restaurant?.restaurantsURL ?? '');
                        }}
                      >
                        <span className="t-font-s pl-3 local-services-content-options-item-link__text">
                          {restaurant.restaurantsTitle}
                        </span>
                        <span className="icon-forward-arrow arrow ml-2 pr-3"></span>
                      </a>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            {dinningRestaurants.length > 4 && (
              <div
                className={`local-services-content-options d-flex flex-column col-12 ${
                  dinningRestaurants.length > 4 ? 'col-md-6' : ''
                } p-0`}
              >
                {dinningRestaurants?.map((restaurant, index) => {
                  if (index >= 4) {
                    return (
                      <div className="local-services-content-options-item">
                        <a
                          href={restaurant?.restaurantsURL}
                          data-custom_click_track_value={`Service Bar | ${restaurant?.restaurantsTitle?.slice(
                            0,
                            20
                          )} | External`}
                          className="custom_click_track local-services-content-options-item-link body-list mx-3 py-3 d-flex align-items-center justify-content-between first track-completed"
                          target={sameTab || cna ? '_self' : '_blank'}
                          rel="noreferrer"
                          onClick={e => {
                            (state?.toLowerCase() === statesConstant.preconnect ||
                              state?.toLowerCase() === statesConstant.connect) &&
                              e.preventDefault();
                            initLetsGetConnected(restaurant?.restaurantsURL ?? '');
                          }}
                        >
                          <span className="t-font-s pl-3 local-services-content-options-item-link__text">
                            {restaurant?.restaurantsTitle}
                          </span>
                          <span className="icon-forward-arrow arrow ml-2 pr-3"></span>
                        </a>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        );

        setshowDinningModal(true);
        document.querySelector('body')?.classList.add('mdc-dialog-scroll-lock');
      }, 200);
      setScrollPosition(window.scrollY);
    } else {
      setshowDinningModal(false);
      initLetsGetConnected(bookNowURL);
    }
  };

  const initLetsGetConnected = (bookNowURL: string | null | undefined) => {
    if (state?.toLowerCase() === statesConstant.preconnect || state?.toLowerCase() === statesConstant.connect) {
      setshowDinningModal(false);
      setServiceData(
        {
          serviceClicked: true,
          serviceUXL: bookNowURL,
        },
        false,
        false,
        {}
      );
      sessionStorage.setItem('amenityClicked', bookNowURL ?? '');
    }
  };

  useEffect(() => {
    if (!showDinningModal) {
      // Scroll to the previous position when the modal is closed
      window.scrollTo(0, scrollPosition);
    }
  }, [showDinningModal, scrollPosition]);

  useEffect(() => {
    if (showDinningModal) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const timeout = setTimeout(() => {
        if (firstdiningMenuRef && firstdiningMenuRef?.current) {
          (firstdiningMenuRef?.current as HTMLInputElement)?.focus();
        }
      }, 20);
    }
  }, [showDinningModal]);

  const closeDinningModal = () => {
    setshowDinningModal(false);
    document.querySelector('body')?.classList.remove('mdc-dialog-scroll-lock');
  };

  return (
    <StyledLocalServicesMenu>
      {services && services?.length > 0 && localServicesCount > 0 && (
        <div className="local-service-wrapper">
          <div
            className={`local-services-content container-fluid color-scheme4 p-0 ${
              localServicesCount === 4 ? 'mobile-carousel' : ''
            } ${localServicesCount > 4 ? 'custom-carousel' : ''} ${localServicesCount > 6 ? 'desktop' : ''}`}
          >
            <div className="local-services-content-backdrop d-none d-md-none"></div>
            <div
              id="localServices"
              className={`${localServicesCount > 3 ? 'glide' : ''} local-services-content-component container px-md-0`}
              data-items={localServicesCount}
              data-desktopperview={localServicesCount}
              data-tabletperview={localServicesCount}
              data-mobileperview={localServicesCount === 4 ? 2 : localServicesCount}
              data-desktoprightpeak={0}
              data-tabletrightpeak={0}
              data-mobilerightpeak={localServicesCount === 4 ? 80 : 0}
            >
              <div className="glide__arrows d-none" data-glide-el="controls">
                <button
                  className="glide__arrow prev local-services-content__left"
                  data-glide-dir="<"
                  aria-label="left-arrow"
                >
                  <span className="icon-back-arrow"></span>
                </button>
                <button
                  className="glide__arrow next local-services-content__right"
                  data-glide-dir=">"
                  aria-label="right-arrow"
                >
                  <span className="icon-forward-arrow"></span>
                </button>
              </div>
              <div className={`${localServicesCount > 3 ? 'glide__track' : ''}`} data-glide-el="track">
                <ul className={`${localServicesCount > 3 ? 'glide__slides' : ''} d-flex`}>
                  {services.map(service => {
                    if (service.onProperty) {
                      return (
                        <li
                          className={`${localServicesCount === 1 ? 'col-12 col-md-6 offset-md-3' : ''} ${
                            localServicesCount === 2 ? 'col-6' : ''
                          } ${localServicesCount === 3 ? 'col-4' : ''} ${
                            localServicesCount > 3 ? 'glide__slide' : ''
                          } local-services-content-item`}
                          key={service.title}
                          onClick={e => {
                            (state?.toLowerCase() === statesConstant.preconnect ||
                              state?.toLowerCase() === statesConstant.connect) &&
                              e.preventDefault();
                            handleClick(service?.id ?? '', service?.bookNowURL ?? '');
                          }}
                        >
                          <span className="d-flex py-4 align-items-center justify-content-center">
                            <a
                              href={service.bookNowURL}
                              className={`d-flex px-2 px-xl-3 px-md-3 mx-0 mx-xl-0 mx-md-1 align-items-center justify-content-center local-services-content-link local-services-content-link-fandb ${
                                localServicesCount > 1 ? 'flex-column' : ''
                              } ${localServicesCount === 1 ? 'flex-row' : ''} ${
                                localServicesCount === 2 ? 'flex-md-row' : ''
                              } ${localServicesCount === 3 || localServicesCount === 4 ? 'flex-xl-row' : ''}`}
                              target={sameTab || cna ? '_self' : service?.target}
                              data-custom_click_track_value={
                                service?.id?.includes(localServiceConstants.spa)
                                  ? 'Service Bar | Spa Reservation | EXTERNAL '
                                  : service?.id?.includes(localServiceConstants.golf)
                                  ? 'Service Bar | Golf Reservation | EXTERNAL '
                                  : service?.id?.includes(localServiceConstants.foodAndBeverage)
                                  ? 'Service Bar | Order F&B | EXTERNAL '
                                  : ''
                              }
                            >
                              <span className={service.iconClass}></span>
                              <p
                                className={`t-label-s pr-2 ${localServicesCount === 1 ? 'pl-3' : ''} ${
                                  localServicesCount === 2 ? 'pt-2 mt-1 pl-md-3 pt-md-0 mt-md-0' : ''
                                } ${
                                  localServicesCount === 3 || localServicesCount === 4
                                    ? 'pt-2 mt-1 mt-md-0 pt-xl-0 pl-xl-3'
                                    : ''
                                } pl-0 mb-0 text-center`}
                              >
                                {service.title}
                              </p>
                              <span className="menu-icon-arrow icon-arrow-right-external d-none d-xl-inline-flex"></span>
                            </a>
                          </span>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            </div>
          </div>
          {showDinningModal && (
            <Ppv5Modal
              showModal={showDinningModal}
              modalTitle={pageProps.diningModalTitle}
              modalContent={modalBody}
              diningModal={true}
              diningModalClass={dinningRestaurants.length > 4 ? 'col-md-10 col-xl-8' : ''}
              closeBtnHandler={closeDinningModal}
            />
          )}
        </div>
      )}
    </StyledLocalServicesMenu>
  );
};
