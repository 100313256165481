// Imports for external libraries go here.
import React from 'react';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { ExploreDestinationsProps } from './ExploreDestinations.types';
import ExploreDestinationsWrapper from './ExploreDestinationsWrapper';
import { EditableComponent } from '@adobe/aem-react-editable-components';

export const ExploreDestinationsWrapperConfig = {
  emptyLabel: 'ExploreDestinations',
  isEmpty: false,
  resourceType: `mi-aem-offers-spa/components/exploredestinations`,
};

export const ExploreDestinationsComp: React.FC<ExploreDestinationsProps> = props => {
  return (
    <div className="explore-destinations-wrapper">
      <ExploreDestinationsWrapper {...props} />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ExploreDestinations = (props: any) => (
  <div data-testid="offers-ExploreDestinations" data-component-name="o-offers-ExploreDestinations">
    <EditableComponent config={ExploreDestinationsWrapperConfig} {...props}>
      <ExploreDestinationsComp {...props} />
    </EditableComponent>
  </div>
);
