import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Heading, Text, Types } from '@marriott/mi-ui-library';
import { WeddingInfoProps } from './WeddingInfo.types';
import { Dropdown, TextFormField } from '../../molecules';
import { DropdownOption } from '../Dropdown/Dropdown.types';

export const WeddingInfo: FC<WeddingInfoProps> = props => {
  const { labels, control, setValue, errors, clearErrors } = props;
  const { title, description, coupleType, firstCoupleLastName, secondCoupleLastName, requiredError } = labels;

  const [spouseTypeOptions, setSpouseTypeOptions] = useState<DropdownOption<unknown>[]>([]);

  useEffect(() => {
    const coupleOptions = coupleType.options.map(type => ({ label: type.label, value: type.code }));
    setSpouseTypeOptions(coupleOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstSpouseTypeField = (
    <Controller
      name="weddingInfo.firstSpouseType"
      control={control}
      render={({ field }) => (
        <Dropdown
          {...field}
          label={''}
          ariaLabel={`${coupleType.ariaLabel} #1`}
          expandIconAriaLabel={`${coupleType.label} ${coupleType.expandIconAriaLabel}`}
          collapseIconAriaLabel={`${coupleType.label} ${coupleType.collapseIconAriaLabel}`}
          options={spouseTypeOptions}
          selectedValue={field.value}
          onChange={option => {
            setValue('weddingInfo.firstSpouseType', option.value);
          }}
        />
      )}
    />
  );

  const firstSpouseLastNameField = (
    <Controller
      name="weddingInfo.firstSpouseLastName"
      control={control}
      rules={{
        required: requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          type="text"
          label={firstCoupleLastName.label}
          ariaLabel={firstCoupleLastName.ariaLabel}
          className="m-input-field"
          maxLength={100}
          showErrorMessage={!!errors.weddingInfo?.firstSpouseLastName?.message}
          errorMessage={errors.weddingInfo?.firstSpouseLastName?.message}
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  const secondSpouseTypeField = (
    <Controller
      name="weddingInfo.secondSpouseType"
      control={control}
      render={({ field }) => (
        <Dropdown
          {...field}
          label={''}
          ariaLabel={`${coupleType.ariaLabel} #2`}
          expandIconAriaLabel={`${coupleType.label} ${coupleType.expandIconAriaLabel}`}
          collapseIconAriaLabel={`${coupleType.label} ${coupleType.collapseIconAriaLabel}`}
          options={spouseTypeOptions}
          selectedValue={field.value}
          onChange={option => {
            setValue('weddingInfo.secondSpouseType', option.value);
          }}
        />
      )}
    />
  );

  const secondSpouseLastNameField = (
    <Controller
      name="weddingInfo.secondSpouseLastName"
      control={control}
      rules={{
        required: requiredError,
      }}
      render={({ field }) => (
        <TextFormField
          {...field}
          label={secondCoupleLastName.label}
          ariaLabel={secondCoupleLastName.ariaLabel}
          className="m-input-field"
          maxLength={100}
          showErrorMessage={!!errors.weddingInfo?.secondSpouseLastName?.message}
          errorMessage={errors.weddingInfo?.secondSpouseLastName?.message}
          onChange={event => {
            field.onChange(event);
            clearErrors(field.name);
          }}
        />
      )}
    />
  );

  return (
    <div data-component-name="m-groups-WeddingInfo" data-testid="groups-WeddingInfo">
      <Heading
        variation={Types.headingType.subtitle}
        element={Types.tags.h2}
        titleText={title}
        fontSize={Types.size.large}
      />
      <Text element={Types.tags.paragraph} copyText={description} fontSize={Types.size.small} customClass="mt-4 mb-2" />
      <div className="row">
        <div className="col-md-3 pb-5">{firstSpouseTypeField}</div>
        <div className="col-md-9 pb-5">{firstSpouseLastNameField}</div>
      </div>
      <div className="row">
        <div className="col-md-3 pb-5">{secondSpouseTypeField}</div>
        <div className="col-md-9 pb-5">{secondSpouseLastNameField}</div>
      </div>
    </div>
  );
};
