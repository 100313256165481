/* eslint-disable @typescript-eslint/no-explicit-any */
// Types for ExploreDestinations go here.
export type ExploreDestinationsProps = {
  model?: any;
  isAuthorMode: boolean;
  mbeData?: any;
};

export type ExploreDestinationsCardProps = {
  // cardId: any;
  // cardProps: any;
  count?: any;
  id?: string;
  title?: string;
  url?: string;
};

export enum BadgeVariation {
  'Inline' = 'inline',
  'Overlay' = 'overlay',
}
