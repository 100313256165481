// Styles for MpoApoOfferDetails go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { mediaQuery, font } from '../../../utils/styles';

export const StyledOfferDetailsSingleProperty = styled.div`
background-color: ${baseVariables.color.neutral10};

.pt-td {
  padding-top: ${toRem(96)};
}
@media ${mediaQuery.mobileOnly} {
  .pt-m {
    padding-top: ${toRem(48)};
  }
}
  .m-details-page {
    font-family: ${font.Swiss721BTRegular};
    padding: ${toRem(16)} ${toRem(16)} ${toRem(64)};
    font-size: ${toRem(16)};
    @media ${mediaQuery.desktop} {
      padding: ${toRem(64)} ${toRem(16)} ${toRem(64)};
    }
    @media ${mediaQuery.mobileOnly} {
      padding: ${toRem(48)} 0 ${toRem(64)};
    }
    @media ${mediaQuery.mobile} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (min-width:993px) and (max-width: 1023px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (min-width:1023px) and (max-width: 1199px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    @media (min-width: 820px) and (max-width: 899px)  {
      max-width: 100%;
      padding-left: 3rem;
      padding-right: 2.6rem;
  }
  @media (min-width: 799px) and (max-width: 818px)  {
    max-width: 100%;
    padding: ${toRem(16)} ${toRem(48)} ${toRem(64)};
}
    @media (min-width: 768px) and (max-width: 799px)  {
      max-width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .m-details-text {
      display: none;
    }
    &-wrapper {
      display: block;
      @media ${mediaQuery.desktop} {
        display: flex;
      }
      h2 {
        color: ${baseVariables.color.merch30};
        font-family: ${font.Swiss721BTRegular}
        font-size: ${toRem(30)};
        font-weight: 500;
        letter-spacing: 0;
        line-height: ${toRem(32)};
        margin-bottom: ${toRem(41)};
        margin-top: 0;

        @media ${mediaQuery.tablet} {
          margin-bottom: ${toRem(8)};
        }
        @media ${mediaQuery.desktop} {
          margin-bottom: ${toRem(12)};
        }
      }
      .m-merch-style-1 {
        padding: 0.375rem 1.25rem;
        font-weight: 600;
        display: inline-block;
        background-color: ${baseVariables.color.memberSolid};
        color: ${baseVariables.color.merch30};
        text-transform: Uppercase;
        text-align: center;
        font-size: ${toRem(14)};
        border-radius: ${toRem(5)};
        margin-bottom: ${toRem(17)};

        @media ${mediaQuery.tablet} {
          margin-bottom: ${toRem(26)};
        }
        @media ${mediaQuery.desktop} {
          margin-bottom: ${toRem(24)};
        }
      }
      .m-tool-tip-hint {
        box-sizing: border-box;
        visibility: hidden;
        text-align: left;
        border-radius: 0.25rem;
        padding: 1rem;
        position: absolute;
        z-index: 1;
        width: ${toRem(270)};
        background-color: ${baseVariables.color.base20};
        color: ${baseVariables.color.merch30};
        border: 0.0625rem solid #d2d2d2;
        font-weight: 500;
        font-size: ${toRem(12)};

        @media ${mediaQuery.mobileOnly} {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          top: 100%;
        }
      }

      .m-whats {
        font-size: ${toRem(12)};
        text-decoration: none;
        margin-left: ${toRem(5)};
        cursor: pointer;
      }
      a:hover + .m-tool-tip-hint,
      a:focus + .m-tool-tip-hint {
        visibility: visible;
      }
      .m-details-page-teaser,
      .m-details-page-description {
        p {
          color: ${baseVariables.color.merch30};
          letter-spacing: 0;
          line-height: ${toRem(24)};
          margin: 0;
          @media ${mediaQuery.desktop} {
            width: ${toRem(568)};
          }
        }
      }
      .m-details-page-description {
        width: ${toRem(569)};
        margin-right: ${toRem(69)};
        @media ${mediaQuery.tabletMax} {
          display: block;
          width: 100%;
        }
        @media ${mediaQuery.mobileOnly} {
          display: block;
          width: 100%;
        }
        .m-details-offer-description{
          a {
            text-decoration: underline;
          }
        }
        .m-details-offer-description ul li a {
            color: inherit !important;
          }
        .m-details-page-reserv-msg {
          margin-top: ${toRem(16)};
          h3 {
            font-size: ${toRem(16)};
            margin: 0;
            font-family: ${font.Swiss721BTRegular}
            @media ${mediaQuery.tablet} {
              font-size: ${toRem(16)};
            }
          }
          p {
            margin-top: ${toRem(9)};
          }
          a {
            text-decoration: underline;
          }
        }
        .t-subtitle-xl {
          color: var(--t-brand-fg-color);
          font: normal normal var(--t-subtitle-xl-font-weight) var(--t-subtitle-xl-font-size-viewport-xs) /
            var(--t-subtitle-xl-line-height-viewport-xs) var(--t-font-family);
          letter-spacing: var(--t-title-s-letter-space);
          text-transform: var(--t-title-s-text-transform);
        }
        .modal-body-offers {
          .terms-modal-content {
            @media ${mediaQuery.mobileOnly} {
              top: 17%;
              left: 0;
              right: 0;
              margin: 0 auto;
              bottom: unset;
            }
          }
          .terms-modal-header {
            .header-heading {
              font-size: ${toRem(22)};
            }
          }
        }
      }
      .m-details-page-TnC {
        margin-top: ${toRem(16)};
        a {
          text-decoration: none;
          font-size: ${toRem(13)};
          letter-spacing: 0;
          line-height: ${toRem(16)};
          font-family: ${font.Swiss721BTRegular}
          cursor: pointer;
          border-bottom: none;
        }
      }
      .desktop-TnC {
        display: none;
        @media ${mediaQuery.desktop} {
          display: block;
        }
      }
      .mobile-TnC {
        @media ${mediaQuery.desktop} {
          display: none;
        }
      }
      .m-details-page-shop-now {
        margin-top: ${toRem(26)};
        @media ${mediaQuery.tablet} {
          margin-top: ${toRem(32)};
        }
        a.primary-btn:focus-visible {
          outline: ${toRem(2)} solid -webkit-focus-ring-color;
          outline-offset: ${toRem(4)};
        }
      }
      .mobile-shop-cta {
        @media ${mediaQuery.desktop} {
          display: none;
        }
        .m-details-button {
          padding-top: 0.5rem;
        }
      }
      .desktop-shop-cta {
        display: none;
        @media ${mediaQuery.desktop} {
          display: block;
        }
        .m-details-button {
          height: ${toRem(48)};
          font-size: 1.125rem;
          padding-top: 0.7rem;
        }
      }

      .m-details-page-need-to-know-wrapper {
        margin-top: ${toRem(32)};

        @media ${mediaQuery.tablet} {
          margin-top: ${toRem(23)};
        }
        @media ${mediaQuery.desktop} {
          margin-top: 0;
          width: ${toRem(472)};
        }

        h3 {
          color: ${baseVariables.color.merch30};
          font-size: ${toRem(16)};
          letter-spacing: 0;
          line-height: ${toRem(20)};
          margin-bottom: 0;
          margin-top: 0;
          font-family: ${font.Swiss721BTRegular}
          @media ${mediaQuery.tablet} {
            font-size: ${toRem(18)};
          }
          @media ${mediaQuery.desktop} {
            font-size: ${toRem(22)};
          }
        }
        .m-details-page-need-to-know-items {
          margin-top: ${toRem(17)};
          line-height: ${toRem(30)};
        }
        .t-subtitle-xl {
          color: var(--t-brand-fg-color);
          font: normal normal var(--t-subtitle-xl-font-weight) var(--t-subtitle-xl-font-size-viewport-xs) /
            var(--t-subtitle-xl-line-height-viewport-xs) var(--t-font-family);
          letter-spacing: var(--t-title-s-letter-space);
          text-transform: var(--t-title-s-text-transform);
        }
      }

      ul {
        padding-left: 1rem;
        list-style-type: disc;
      }
      ul li {
        position: relative;
      }
      .m-how-to-book {
        margin-top: ${toRem(19)};
        display: none;
        margin-bottom: ${toRem(40)};
        line-height: ${toRem(24)};
        @media ${mediaQuery.desktop} {
          margin-bottom: ${toRem(78)};
        }
        p {
          line-height: ${toRem(24)};
        }
      }
      .m-how-to-book > div {
        margin-bottom: ${toRem(24)};
      }
      .details-visible {
        display: block;
      }
      [class^='icon-'] {
        padding: 0 ${toRem(4)};
        font-size: ${toRem(12)};
      }
      .show-more {
        margin-top: ${toRem(9)};
        padding-left: ${toRem(16)};
        margin-bottom: ${toRem(40)};
        font-size: ${toRem(14)};
        letter-spacing: 0;
        line-height: ${toRem(24)};
      }
      .show-more-expanded {
        margin-top: ${toRem(9)};
        padding-left: ${toRem(16)};
        font-size: ${toRem(14)};
        letter-spacing: 0;
        line-height: ${toRem(24)};
      }
    }
  }
  .spo-details {
    @media ${mediaQuery.tablet} {
      padding-top: ${toRem(144)};
    }
    @media ${mediaQuery.desktop} {
      padding-top: ${toRem(144)};
    }
    @media ${mediaQuery.mobileOnly} {
      padding-top: ${toRem(96)};
    }
    .icon-forward-arrow {
      color: var(--t-link-text-color);
    }
  }

  img {
    width: 100%;
  }
  .error-message {
    border: ${toRem(1)} solid ${baseVariables.color.alert50};
    border-radius: ${toRem(8)};
    background-color: rgb(252, 235, 237);
    margin-top: ${toRem(16)};
    margin-bottom: ${toRem(-18)};
    .m-message-inline.col-10{
        flex: unset;
        max-width: 100%;
        border: none;
        background-color: rgb(252, 235, 237);
        padding: ${toRem(12)} ${toRem(20)};
    }
    .m-message-content-wrap{
        &:before{
            color:${baseVariables.color.alert50};
        }
        color: ${baseVariables.color.alert50};
    }
  }
`;
