// Styles for LocalHeader go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables, contextualVariable } from '@marriott/mi-ui-library';

const $glideBulletBgColor = '#8c8c8c';
const $activeGlideBulletBgColor = '#51555a';

export const StyledLocalServicesCards = styled.div`
  ${rtl`
  .card-component {
    height: 100%;
    &-content {
      &-line-seperator {
        background-color: ${contextualVariable.linkNavBorderColor};
        height: ${toRem(1)};
        width: ${toRem(42)};
      }
      &-cta {
        height: ${toRem(32)};
        width: 100%;
        white-space: nowrap;
        &-anchor {
          position: absolute;
          bottom: ${toRem(32)};
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &-container {
      position: relative;
      height: inherit;
      border: ${toRem(1)} solid ${baseVariables.color.neutral20};
      border-radius: ${toRem(14)};
      background-color: ${baseVariables.color.base20};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
      border-bottom: ${toRem(10)} solid ${contextualVariable.linkNavBorderColor};
      &-image {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        overflow: hidden;
      }
      &-img {
        border-top-left-radius: ${toRem(14)};
        border-top-right-radius: ${toRem(14)};
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .services-carousel-content {
    &-component {
      overflow: visible;
      .glide__arrows {
        position: relative;
        top: 50%;
        bottom: 50%;
        left: ${toRem(-20)};
        right: ${toRem(-20)};
        .glide__arrow {
          box-sizing: border-box;
          height: ${toRem(40)};
          width: ${toRem(40)};
          border-radius: 50%;
          padding: ${toRem(9)} ${toRem(12)};
          border: ${toRem(1)} solid ${baseVariables.color.neutral20};
          background-color: ${baseVariables.color.base20};
          box-shadow: 0 ${toRem(2)} ${toRem(20)} 0 rgba(0, 0, 0, 0.09);
          position: absolute;
          margin-top: ${toRem(256)};
          margin-right: ${toRem(-40)};

          [class*='icon-'],
          [class*='icon-']::before {
            color: ${baseVariables.color.neutral00};
          }
          &:hover {
            box-shadow: 0 ${toRem(4)} ${toRem(10)} 0 rgba(0, 0, 0, 0.3);
          }
          &.prev {
            left: 0;
          }
          &.next {
            right: 0;
          }
          &--disabled {
            display: none;
          }
        }
      }
      .glide__track {
        width: 100%;
        .glide__slide {
          height: auto;
        }
      }
    }
  }
  .v2-amenity-cards-wrapper {
    .services-carousel-content {
      &-component {
        .glide__arrows {
          .glide__arrow {
            box-shadow: 0 ${toRem(2)} ${toRem(20)} 0 rgba(0, 0, 0, 0.09);
            position: absolute;
            margin-top: ${toRem(256)};
            margin-right: ${toRem(-40)};

            [class*='icon-'],
            [class*='icon-']::before {
              color: ${contextualVariable.linkNavBorderColor};
            }
          }
        }
        .glide__bullets {
          display: flex;
          justify-content: center;
          position: relative;
          margin-top: ${toRem(48)};

          .glide__bullet {
            background-color: ${$glideBulletBgColor};
            &.glide__bullet--active {
              background-color: ${$activeGlideBulletBgColor};
            }
          }
        }
      }
    }
  }
  .services-card-skeleton {
    &_title {
      height: ${toRem(33)};
    }
    &_label {
      height: ${toRem(22)};
    }
    &_seperator {
      background-color: ${baseVariables.color.neutral20};
    }
    &_description {
      height: ${toRem(80)};
    }
    &_cta {
      width: ${toRem(130)};
      height: ${toRem(24)};
    }
  }
  `}
`;
