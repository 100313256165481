/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useContext, useState } from 'react';
import Glide from '@glidejs/glide';
import { useServiceStore, serviceStore } from '../../../modules/store/serviceStore';
import { PageParamsContext } from '../../../modules/context/PageContext';
import { LocalServicesCardsProps, Node, imageNodeProps, ImageProps, RenditionsProps } from './LocalServicesCards.types';
import { imageQueryParam } from '../LocalServices.types';
import { StyledLocalServicesCards } from './LocalServicesCards.styles';
import { Image } from '@marriott/mi-ui-library';
import { common_words } from '../../../modules/utils/constants/constants';
import { statesConstant, localServiceConstants, cacheURL } from '../../../modules/utils/constants';

export const LocalServicesCards: FC<LocalServicesCardsProps> = pageProps => {
  const setServiceData = useServiceStore((state: serviceStore) => state.setservice);
  const [servicesCards, setServicesCards] = useState<any[]>([]);
  const [servicesCardsCount, setServicesCardsCount] = useState(0);
  const { preProcessorResponse } = useContext(PageParamsContext);
  const { state = '', sameTab = false, cna = false, disableImageModifier } = preProcessorResponse || {};

  useEffect(() => {
    const calculateRenditions = (serviceCards: any[]) => {
      if (!disableImageModifier && serviceCards && serviceCards?.length) {
        const updatedServiceCards: any[] = [];
        serviceCards.forEach(serviceCard => {
          const imageQueryParamsAmenities: Array<imageQueryParam> = pageProps?.imageQueryParamsAmenities ?? [];
          if (serviceCard?.dynamicMedia?.renditions && serviceCard?.dynamicMedia?.renditions?.length) {
            updatedServiceCards.push({
              ...serviceCard,
            });
          } else {
            const renditions: Array<RenditionsProps> = [];
            imageQueryParamsAmenities.forEach(imageQueryParamsAmenity => {
              renditions.push({
                mediaQuery: imageQueryParamsAmenity?.viewPortName?.split(':')[0],
                mediaValue: imageQueryParamsAmenity?.viewPortName?.split(':')[1],
                width: 400,
                dynamic: true,
                renditionPath: `${serviceCard?.defaultImageURL}?${imageQueryParamsAmenity?.nonRetinaDisplayParams}, ${serviceCard?.defaultImageURL}?${imageQueryParamsAmenity?.retinaDisplayParams}`,
              });
            });
            updatedServiceCards.push({
              ...serviceCard,
              dynamicMedia: {
                ...serviceCard.dynamicMedia,
                renditions: renditions,
              },
            });
          }
        });
        return updatedServiceCards;
      }
      return serviceCards;
    };

    let card: any[] = [];
    if (pageProps?.property?.basicInformation?.isOrderFoodAndBeverageByMobileSupported) {
      card = [
        ...card,
        {
          id: localServiceConstants.foodAndBeverage,
          title: pageProps?.foodBeverageCard?.foodBeverageCardLabel,
          label: pageProps?.foodBeverageCard?.foodBeverageTitle,
          description: pageProps?.foodBeverageCard?.foodBeverageDescription,
          dynamicMedia: pageProps?.foodBeverageCard?.dynamicMedia,
          defaultImageURL: pageProps?.foodBeverageCard?.foodBeverageImageSrc,
          altText: pageProps?.foodBeverageCard?.altText ?? pageProps?.foodBeverageCard?.dynamicMedia?.altText,
          ctaLink: pageProps?.foodBeverageCard?.foodBeverageCtaLink,
          ctaLabel: pageProps?.foodBeverageCard?.foodBeverageCtaLabel,
        },
      ];
    }
    const reservations = pageProps?.property?.restaurants?.edges.filter(
      edge => edge?.node?.onProperty === true && edge?.node?.reservationURL
    );

    const AEMDinningImages = pageProps?.diningReservationsCard;

    const reservationImages = restaurantImageValidation(AEMDinningImages, reservations);

    if (reservations?.length) {
      reservations?.forEach((reservation, index) => {
        const hasImage =
          addImagePref(reservationImages?.[index]?.dynamicMedia?.damPath) ??
          addImagePref(reservationImages?.[index]?.classicHorizontal);
        card = [
          ...card,
          {
            id: localServiceConstants.dinning,
            title: pageProps?.diningCardLabel,
            label: reservation?.node?.name,
            description: reservation?.node?.description,
            dynamicMedia:
              addImagePref(reservationImages?.[index]?.dynamicMedia) ??
              (!hasImage ? addImagePref(pageProps?.diningFallbackImage) : []),
            defaultImageURL:
              addImagePref(reservationImages?.[index]?.dynamicMedia?.damPath) ??
              addImagePref(reservationImages?.[index]?.classicHorizontal) ??
              addImagePref(pageProps?.diningFallbackImage?.damPath),
            altText:
              reservationImages?.[index]?.dynamicMedia?.altText ?? reservationImages?.[index]?.alternateDescription,
            ctaLink: reservation?.node?.reservationURL,
            ctaLabel: pageProps?.diningCtaLabel,
          },
        ];
      });
    }

    const spaData = pageProps?.property?.spa?.edges?.find(
      edge => edge?.node?.onProperty === true && edge?.node?.bookNowURL
    );

    const AEMSpaImages = pageProps?.spaCard;

    const spaMedia = pageProps?.property?.media?.photoGallery?.spa?.edges;

    const spaImages = SpaImageValidation(AEMSpaImages, spaMedia, spaData);
    const dynamicMedia =
      spaImages && (spaImages?.dynamicMedia ?? spaImages?.classicHorizontal)
        ? addImagePref(spaImages?.dynamicMedia) ?? addImagePref(spaImages?.classicHorizontal)
        : pageProps?.spaFallbackImage
        ? addImagePref(pageProps?.spaFallbackImage)
        : '';

    const defaultImageURL =
      spaImages && (spaImages?.dynamicMedia ?? spaImages?.classicHorizontal)
        ? addImagePref(spaImages?.dynamicMedia?.damPath) ?? addImagePref(spaImages?.classicHorizontal)
        : pageProps?.spaFallbackImage
        ? addImagePref(pageProps?.spaFallbackImage?.damPath)
        : '';

    if (spaData && spaData?.node?.onProperty) {
      card = [
        ...card,
        {
          id: localServiceConstants.spa,
          title: pageProps?.spaCardLabel,
          label: spaData?.node?.name,
          description: spaData?.node?.description,

          dynamicMedia: dynamicMedia,
          defaultImageURL: defaultImageURL,
          altText: spaImages?.dynamicMedia?.altText ?? spaImages?.alternateDescription,
          ctaLink: spaData?.node?.bookNowURL,
          ctaLabel: pageProps?.spaCtaLabel,
        },
      ];
    }

    pageProps?.epicgolfCardList?.forEach(golfCard => {
      card = [
        ...card,
        {
          id: localServiceConstants.golf,
          title: pageProps?.golfCardLabel,
          label: golfCard?.title,
          description: golfCard?.description,
          dynamicMedia: addImagePref(golfCard?.dynamicServiceCardImage) ?? addImagePref(pageProps?.golfFallbackImage),
          defaultImageURL: addImagePref(golfCard?.imageSrc) ?? addImagePref(pageProps?.spaFallbackImage?.damPath),
          altText: golfCard?.dynamicServiceCardImage?.altText,
          ctaLink: golfCard?.ctaLink,
          ctaLabel: pageProps?.golfCtaLabel,
        },
      ];
    });
    if (!pageProps?.loading && pageProps?.property?.basicInformation) {
      const updatedCards = calculateRenditions(card);
      setServicesCards([...updatedCards]);
      setServicesCardsCount(updatedCards?.length);
    }
  }, [pageProps?.loading]);

  useEffect(() => {
    let glide: any;
    if (servicesCardsCount > 1) {
      glide = new Glide('#ServiceCarousel', {
        type: 'slider',
        gap: 16,
        perView: 3,
        keyboard: false,
        autoplay: false,
        hoverpause: true,
        rewind: false,
        bound: true,
        direction: document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr',
        breakpoints: {
          1199: {
            perView: 2,
            peek: {
              before: 0,
              after: 0,
            },
          },
          767: {
            rewind: true,
            type: 'carousel',
            perView: 1,
            peek: {
              before: 16,
              after: 16,
            },
          },
        },
      });
      glide.mount();
      setServiceData(
        {
          servicesLoaded: true,
        },
        false,
        false,
        {}
      );
    }

    return () => {
      if (glide) {
        glide?.destroy();
      }
    };
  }, [servicesCardsCount, servicesCards]);

  const addImagePref = (rendition: ImageProps | string | undefined) => {
    if (rendition && typeof rendition !== 'string' && rendition?.renditions && rendition?.renditions?.length > 0) {
      rendition?.renditions?.map((ren: RenditionsProps) => {
        if (ren?.renditionPath?.indexOf('https://') === -1) {
          ren.renditionPath = `${cacheURL}${ren?.renditionPath}`;
        }
        if (ren?.damPath?.indexOf('https://') === -1) {
          ren.damPath = `${cacheURL}${ren?.damPath}`;
        }
      });
    } else {
      if (rendition && typeof rendition === 'string') {
        if (rendition?.indexOf('https://') === -1) {
          rendition = `${cacheURL}${rendition}`;
        }
      }
    }
    return rendition;
  };

  const restaurantImageValidation = (AEMImages: any[], uxlArray: any[] | undefined) => {
    const reservationImg: any = new Array(uxlArray?.length ?? 0).fill(null);

    // Step I :: Match the productID
    uxlArray?.forEach((uxlItem, index) => {
      const productIdIndex = AEMImages?.findIndex(
        aem => aem?.productId && (aem?.productId ?? '').includes(uxlItem?.node?.productId)
      );

      if (productIdIndex !== -1) {
        reservationImg[index] = AEMImages[productIdIndex];
        AEMImages?.splice(productIdIndex, 1);
      } else {
        reservationImg[index] = null;
      }
    });

    // For Non-EN Locale FCFS (First Come First Serve Basis)
    if (preProcessorResponse?.locale?.includes('en')) {
      // Step II :: Identify the AEMImages that are most closely correlated with the reservations.
      AEMImages?.forEach((aem, index) => {
        let percentage = 0;
        let maxpercentage = 0;
        let altTextIndex: number | null = null;
        uxlArray?.forEach((uxlItem, uxlIndex) => {
          if (!reservationImg[uxlIndex]) {
            percentage = fuzzySearch(uxlItem?.node?.name, aem?.altText ?? aem?.dynamicMedia?.altText);
            if (maxpercentage < percentage) {
              maxpercentage = percentage;
              altTextIndex = uxlIndex;
            }
          }
        });

        if (altTextIndex || altTextIndex === 0) {
          reservationImg[altTextIndex] = AEMImages[index];
          AEMImages?.splice(index, 1);
        }
      });
    }

    // Step III :: Check if we have additional AEMImages left in the array and update it to reservation that does not have one
    reservationImg?.forEach((_reservation: any, index: number) => {
      if (AEMImages.length && !reservationImg[index]) {
        reservationImg[index] = AEMImages[0];
        AEMImages?.splice(0, 1);
      }
    });

    // Step IV :: Call the UXL Logic if number of reservations > AEM Images, to get missing images from UXL
    reservationImg?.forEach((_reservation: any, index: number) => {
      if (!reservationImg[index]) {
        reservationImg[index] = uxlLogic(uxlArray?.[index]);
      }
    });

    return [...reservationImg];
  };

  const SpaImageValidation = (AEMImages: string | any[], uxlArray: any[] | undefined, spaData: Node | undefined) => {
    if (AEMImages?.length > 0) {
      return AEMImages[0];
    } else {
      if (preProcessorResponse?.locale?.includes('en')) {
        let percentage = 0;
        let maxpercentage = 0;
        let selectedUXLItem: imageNodeProps | undefined;
        uxlArray?.forEach(item => {
          percentage = fuzzySearch(item?.node?.caption, spaData?.node?.name ?? '');
          if (maxpercentage < percentage && item?.node?.imageUrls?.classicHorizontal) {
            maxpercentage = percentage;
            selectedUXLItem = item;
          }
        });
        if (selectedUXLItem) {
          return {
            classicHorizontal: selectedUXLItem?.node?.imageUrls?.classicHorizontal,
            alternateDescription: selectedUXLItem?.node?.alternateDescription,
          };
        } else {
          return {
            classicHorizontal: uxlArray?.[0]?.node?.imageUrls?.classicHorizontal,
            alternateDescription: uxlArray?.[0]?.node?.alternateDescription,
          };
        }
      } else {
        return {
          classicHorizontal: uxlArray?.[0]?.node?.imageUrls?.classicHorizontal,
          alternateDescription: uxlArray?.[0]?.node?.alternateDescription,
        };
      }
    }
  };

  const uxlLogic = (item: { node: { media: { imageConnection: { edges: any[] } }; name: any } }) => {
    let selectedUXLItem;
    if (preProcessorResponse?.locale?.includes('en')) {
      selectedUXLItem = item?.node?.media?.imageConnection?.edges?.find(
        edge =>
          edge?.node?.isPrimaryServices === true &&
          edge?.node?.imageUrls?.classicHorizontal &&
          edge?.node?.imageUrls?.square
      );
      if (selectedUXLItem) {
        return {
          classicHorizontal: selectedUXLItem?.node?.imageUrls?.classicHorizontal,
          square: selectedUXLItem?.node?.imageUrls?.square,
          alternateDescription: selectedUXLItem?.node?.alternateDescription,
        };
      } else {
        selectedUXLItem = item?.node?.media?.imageConnection?.edges?.find(
          edge =>
            edge?.node?.defaultImage === true &&
            edge?.node?.imageUrls?.classicHorizontal &&
            edge?.node?.imageUrls?.square
        );
        if (selectedUXLItem) {
          return {
            classicHorizontal: selectedUXLItem?.node?.imageUrls?.classicHorizontal,
            square: selectedUXLItem?.node?.imageUrls?.square,
            alternateDescription: selectedUXLItem?.node?.alternateDescription,
          };
        } else {
          let percentage = 0;
          let maxpercentage = 0;
          let selectedUXLItem: imageNodeProps | undefined;
          item?.node?.media?.imageConnection?.edges?.forEach(edge => {
            percentage = fuzzySearch(item?.node?.name, edge?.node?.alternateDescription);
            if (
              maxpercentage < percentage &&
              edge?.node?.imageUrls?.classicHorizontal &&
              edge?.node?.imageUrls?.square
            ) {
              maxpercentage = percentage;
              selectedUXLItem = edge;
            }
          });
          if (selectedUXLItem) {
            return {
              classicHorizontal: selectedUXLItem?.node?.imageUrls?.classicHorizontal,
              square: selectedUXLItem?.node?.imageUrls?.square,
              alternateDescription: selectedUXLItem?.node?.alternateDescription,
            };
          } else {
            return {
              classicHorizontal: item?.node?.media?.imageConnection?.edges?.[0]?.node?.imageUrls?.classicHorizontal,
              square: item?.node?.media?.imageConnection?.edges?.[0]?.node?.imageUrls?.square,
              alternateDescription: item?.node?.media?.imageConnection?.edges?.[0]?.node?.alternateDescription,
            };
          }
        }
      }
    } else {
      return {
        classicHorizontal: item?.node?.media?.imageConnection?.edges?.[0]?.node?.imageUrls?.classicHorizontal,
        square: item?.node?.media?.imageConnection?.edges?.[0]?.node?.imageUrls?.square,
        alternateDescription: item?.node?.media?.imageConnection?.edges?.[0]?.node?.alternateDescription,
      };
    }
  };

  const initLetsGetConnected = (event: any, bookNowURL = '') => {
    if (state?.toLowerCase() === statesConstant.preconnect || state?.toLowerCase() === statesConstant.connect) {
      event.preventDefault();
      setServiceData(
        {
          serviceClicked: true,
          serviceUXL: bookNowURL,
        },
        false,
        false,
        {}
      );
      sessionStorage.setItem('amenityClicked', bookNowURL);
    }
  };

  const fuzzySearch = (name: string, altText: string) => {
    const updatedName = name?.replaceAll(common_words, '') ?? '';
    const updatedAltText = altText?.replaceAll(common_words, '') ?? '';
    const a = prep(updatedName);
    const b = prep(updatedAltText);
    const bg1 = bigrams(a);
    const bg2 = bigrams(b);
    const c1 = count(bg1);
    const c2 = count(bg2);
    const combined: number = [...new Set([...bg1, ...bg2])]?.reduce((t, k) => t + Math.min(c1[k] || 0, c2[k] || 0), 0);
    return (2 * combined) / Math.max(bg1.length + bg2.length, 1);
  };

  //logic for fuzzy search starts here
  const prep = (str: string) =>
    str
      ?.toLowerCase()
      ?.replace(/[^\w\s]/g, ' ')
      ?.replace(/\s+/g, ' ');

  const bigrams = (str: string) => [...str]?.slice(0, -1)?.map((c, i) => c + str[i + 1]);

  const count = (xs: any[]) => xs?.reduce((a, x) => ((a[x] = (a[x] || 0) + 1), a), {});
  //logic for fuzzy search ends here

  return (
    <StyledLocalServicesCards>
      {pageProps?.loading && servicesCardsCount === 0 && (
        <div className="pt-5 mt-3 pb-4 mb-3 v2-amenity-cards-wrapper">
          <div className="container py-1">
            <ul className="d-md-flex justify-content-md-center">
              {Array.from(
                {
                  length: 3,
                },
                (_, index) => (
                  <li
                    className={`col-12 px-3 px-md-2 col-md-6 col-xl-4 mb-0 services-card-skeleton ${
                      index === 0
                        ? 'd-block'
                        : index === 1
                        ? 'd-none d-md-block'
                        : index === 2
                        ? 'd-none d-xl-block'
                        : ''
                    }`}
                  >
                    <div className="card-component">
                      <div className="container px-0 card-component-container">
                        <div className="skeleton-loader mx-sm-auto mx-md-0 card-component-container-image services-card-skeleton_img"></div>
                        <div className="card-component-content py-4 my-2 px-4 mx-2 align-items-center d-flex flex-column justify-content-center">
                          <div className="skeleton-loader mx-sm-auto mx-md-0 mb-5 text-center align-self-stretch services-card-skeleton_title"></div>
                          <div className="skeleton-loader mx-sm-auto mx-md-0 card-component-content-line-seperator mb-3 services-card-skeleton_seperator"></div>
                          <div className="skeleton-loader mx-sm-auto mx-md-0 mt-4 mb-3 text-center align-self-stretch services-card-skeleton_label"></div>
                          <div className="skeleton-loader mx-sm-auto mx-md-0 text-center pb-4 mb-2 align-self-stretch services-card-skeleton_description"></div>
                          <div className="card-component-content-cta">
                            <div className="skeleton-loader mx-sm-auto mx-md-0 card-component-content-cta-anchor services-card-skeleton_cta"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      )}
      {!pageProps?.loading && servicesCardsCount > 0 && servicesCards && servicesCards.length > 0 && (
        <div
          id={pageProps?.contentID}
          className={`pt-5 mt-4 pb-4 v2-amenity-cards-wrapper ${servicesCards.length <= 3 ? 'mb-xl-3' : ''}
          ${servicesCards.length <= 2 ? 'mb-md-3' : ''}
        ${servicesCards.length === 1 ? 'mb-3' : ''}`}
        >
          <div className="py-1">
            <div className="heading-container-component d-flex justify-content-center align-items-center container pb-5 mb-3 px-xl-0">
              <div className="d-flex flex-column col-10 col-xl-6 align-items-center justify-content-center px-0">
                {pageProps.heading && (
                  <h3 className="t-title-s heading-container-component__headline text-center mb-0">
                    {pageProps.heading}
                  </h3>
                )}
                {pageProps?.description && (
                  <p className="heading-container-component__description t-font-s mb-0 mt-2 text-center">
                    {pageProps.description}
                  </p>
                )}
              </div>
            </div>
            <div className="services-carousel-content">
              <div className="container services-carousel-content-container px-0 px-md-2">
                <div
                  id="ServiceCarousel"
                  className={`services-carousel-content-component ${
                    servicesCardsCount > 1 ? 'glide glide--carousel' : ''
                  } `}
                  data-cards={servicesCardsCount}
                >
                  <div
                    data-glide-el="controls"
                    className={`glide__arrows d-none ${servicesCardsCount > 3 ? 'd-md-block' : ''} ${
                      servicesCardsCount < 3 ? '' : ''
                    } ${servicesCardsCount === 3 ? 'd-md-block d-xl-none' : ''}`}
                  >
                    <button
                      className="glide__arrow prev services-carousel-content__left"
                      data-glide-dir="<"
                      aria-label="left-arrow"
                    >
                      <span className="icon-arrow-left"></span>
                    </button>
                    <button
                      className="glide__arrow next services-carousel-content__right"
                      data-glide-dir=">"
                      aria-label="right-arrow"
                    >
                      <span className="icon-arrow-right"></span>
                    </button>
                  </div>
                  <div
                    className={`${servicesCardsCount < 3 ? 'd-md-flex justify-content-md-center' : ''} glide__track`}
                    data-glide-el="track"
                  >
                    <ul
                      className={`${
                        servicesCardsCount < 2 ? 'col-12 px-3 px-md-1 col-md-6 col-xl-4' : ''
                      } glide__slides mb-0`}
                    >
                      {servicesCards.map(card => {
                        return (
                          <li className="glide__slide">
                            <div className="card-component">
                              <div className="container px-0 card-component-container">
                                {/* Image to be updated */}
                                <div className="card-component-container-image">
                                  <Image
                                    dynamic={true}
                                    defaultImageURL={card?.defaultImageURL}
                                    altText={card?.altText}
                                    renditions={card?.dynamicMedia?.renditions || []}
                                    customClass="card-component-container-img"
                                  />
                                </div>
                                <div className="card-component-content py-4 my-2 px-4 mx-2 align-items-center d-flex flex-column justify-content-center">
                                  <h4 className="t-subtitle-xl pb-4 text-center align-self-stretch">{card.title}</h4>
                                  <div className="card-component-content-line-seperator mb-3"></div>
                                  <div className="t-subtitle-m pt-4 pb-3 text-center align-self-stretch">
                                    {card.label}
                                  </div>
                                  <div className="t-font-s text-center pb-4 mb-2 align-self-stretch">
                                    {card.description}
                                  </div>
                                  <div className="card-component-content-cta">
                                    <a
                                      href={`${card?.ctaLink}`}
                                      title="card-link"
                                      target={sameTab || cna ? '_self' : '_blank'}
                                      rel="noreferrer"
                                      className="card-component-content-cta-anchor m-button-s m-button-secondary text-center custom_click_track"
                                      data-custom_click_track_value={`Expanded Service | ${
                                        card?.id?.includes(localServiceConstants.dinning)
                                          ? `${card?.label?.slice(0, 20)}`
                                          : card?.id?.includes(localServiceConstants.golf)
                                          ? 'Golf Reservation 0'
                                          : card?.id?.includes(localServiceConstants.spa)
                                          ? 'Spa Reservation 0'
                                          : 'Order F&B'
                                      }| EXTERNAL`}
                                      onClick={e => initLetsGetConnected(e, `${card?.ctaLink}`)}
                                    >
                                      {card.ctaLabel}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <ul
                    className={`glide__bullets ${servicesCards.length <= 3 ? 'd-xl-none' : ''}
                        ${servicesCards.length <= 2 ? 'd-md-none' : ''}
                      ${servicesCards.length === 1 ? 'd-none' : ''}`}
                    data-glide-el="controls[nav]"
                  >
                    {servicesCards.map((_, index) => {
                      return <li className="glide__bullet" data-glide-dir={index}></li>;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledLocalServicesCards>
  );
};
