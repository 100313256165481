import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { OffersHeroV2Props } from './OffersHeroV2.types';
import { Hero } from '@marriott/mi-ui-library';
import { StyledOffersHeroV2 } from './OffersHeroV2.styles';
import { decodeHtml, getMediaData, getMediaSource } from '../../utils/OfferUtils';
import { PRIMARY_MEDIA, SECONDARY_MEDIA } from '../../constants/CommonConstants';
import { ImageRenditionsData } from '../../utils/ImageRenditionStructure';

export const OffersHeroV2WrapperConfig = {
  emptyLabel: 'OffersHeroBannerV2',
  isEmpty: false,
  resourceType: `mi-aem-common-spa/components/content/hero`,
};

export const OffersHeroV2Wrapper: React.FC<OffersHeroV2Props> = props => {
  const { model, offersData, isAuthorMode } = props;
  const { offersHeroV2 } = ImageRenditionsData;
  const offersDataNode = offersData?.responseObject?.edges[0];
  const mediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        PRIMARY_MEDIA,
        offersHeroV2?.aspectRatio?.dynamicMedia
      );
  const isMediaFromDAC = mediaSource === PRIMARY_MEDIA;
  const media = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersHeroV2?.dynamicMedia,
        mediaSource,
        PRIMARY_MEDIA
      );

  const logoMediaSource = isAuthorMode
    ? 'model'
    : getMediaSource(
        offersData?.responseObject?.edges[0]?.node?.media,
        SECONDARY_MEDIA,
        offersHeroV2?.aspectRatio?.dynamicMediaLogo
      );
  const isLogoMediaFromDAC = logoMediaSource === SECONDARY_MEDIA;
  const logoMedia = isAuthorMode
    ? { rendition: [], fileReferenceBackgroundImage: '' }
    : getMediaData(
        offersData?.responseObject?.edges[0]?.node?.media,
        offersHeroV2?.dynamicMediaLogo,
        logoMediaSource,
        SECONDARY_MEDIA
      );

  return (
    <StyledOffersHeroV2 data-component-name="o-offers-OffersHeroV2" data-testid="OffersHeroV2">
      <Hero
        eyebrowText={decodeHtml(offersDataNode?.node?.descriptionTeaser) ?? model?.eyebrowText}
        iconAltText={''}
        header={offersDataNode?.node?.title ? decodeHtml(offersDataNode.node.title) : model?.header}
        description={decodeHtml(offersDataNode?.node?.description) ?? model?.description}
        primaryCtaLink={offersDataNode?.node?.callToActionLink ?? model?.primaryCtaLink}
        primaryCtaText={offersDataNode?.node?.callToActionLabel ?? model?.primaryCtaText}
        primaryCtaOpenInNewTab={model?.primaryCtaOpenInNewTab}
        secondaryCtaLink={offersDataNode?.merchandisingNode?.secondaryCallToActionLink ?? model.secondaryCtaLink}
        secondaryCtaText={offersDataNode?.merchandisingNode?.secondaryCallToActionLabel ?? model?.secondaryCtaText}
        secondaryCtaOpenInNewTab={model?.secondaryCtaOpenInNewTab}
        iconText={offersDataNode?.merchandisingNode?.caption ?? model?.iconText}
        styleclass={model?.styleclass}
        dynamicMedia={isMediaFromDAC ? media : model?.dynamicMedia}
        dynamicMediaLogo={isLogoMediaFromDAC ? logoMedia : model?.dynamicMediaLogo}
        eyebrowType={model?.eyebrowType}
        assetType={model?.assetType}
        variation={model?.variation}
        linkUrl={offersDataNode?.merchandisingNode?.captionUrl ?? model?.linkUrl}
        iconFontPath={model?.iconFontPath}
        iconPath={model?.iconPath}
      />
    </StyledOffersHeroV2>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OffersHeroV2 = (props: any) => (
  <EditableComponent config={OffersHeroV2WrapperConfig} {...props}>
    <div className="m-container-fullbleed" data-testid="hero">
      <OffersHeroV2Wrapper {...props} />
    </div>
  </EditableComponent>
);
