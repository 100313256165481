export class Constants {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static usageAEM: any = {
    ELITE: 'ELITE',
    MEMBER: 'MEMBER',
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static usage: any = {
    FREE: 'FREE',
    PURCHASED: 'PURCHASED',
    ELITE: 'ELITE',
    MEMBER: 'MEMBER',
    ACCESS: 'ACCESS',
  };
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static plan: any = {
    PLAN1: 'PLAN1',
    PLAN2: 'PLAN2',
  };
}

export const INTERNET_BAR_INITIAL_STATE = {
  title: '',
  welcomeText: '',
  description: '',
  isConnectState: false,
  connectUpgradeBtn: '',
  signInButton: {
    signInButtonLink: '',
    signInButtonLabel: '',
    signInButtonLinkInNewTab: '',
  },
  joinNowButton: {
    joinButtonLabel: '',
    joinButtonLink: '',
    joinButtonLinkInNewTab: '',
  },
  termsAndConditions: '',
  signInText: '',
  noLoginInterfaceModal: false,
  showLoginModal: false,
  loginModal: {
    default: false,
    separatorLabel: false,
    userAccessCodeLabel: false,
    requestAccessCodeLabel: false,
    errorMessage: false,
    connectNowLabel: false,
  },
  showAccessModal: false,
  accessModal: {
    default: false,
    acErrorMessage: false,
  },
  showReqAccessModal: false,
  reqAccessModal: {
    default: false,
    racErrorMessage: false,
  },
  showUpgradeGenErr: false,
  showUpgradeLRErr: false,
  showUpgradeMaxErr: false,
  showUpgradeNoCCErr: false,
  showUpgradePMSErr: false,
  showUpgradeSuccessNAInfo: false,
  showUpgradeSuccessMAXInfo: false,
  showPricingModal: false,
  showUpgradeModal: false,
};

export const LOYALTY_ENABLED_ZONES = ['GUEST', 'PUBLIC', 'LOBBY'];
export const LOYALTY_DISABLED_ZONES = ['CONFERENCE', 'MEETING'];
export enum LOYALTY_ENROLLMENT_SOURCE {
  WFS8,
  WFC5,
}

export const LOYALTY_MR_ENROLLMENT_SOURCE = ['WFS8', 'WFC5'];
export const DEFAULT_LOYALTY_OBJ = {
  mr_enrolled: false,
  mr_enrollment_source: '',
};

export const DEFAULT_LOCALE = 'en-US';

export const LOCALE_SUPPORT_NO_SPACE_C_PRICE_MODAL = ['ko-kr'];
