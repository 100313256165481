import { FC, useContext, useEffect, useState } from 'react';
import { Config, EditableComponent, MappedComponentProperties } from '@adobe/aem-react-editable-components';
import { useQuery } from '@apollo/client';
import { PageParamsContext } from '../../modules/context/PageContext';
import { AccuWeatherProps, forecastType } from './AccuWeather.types';
import { StyledAccuWeather } from './AccuWeather.styles';
import { accuWeather } from '../../modules/graphql/index';
import { weatherIcons } from '../../modules/utils/constants/constants';

import { AccuWeatherMockUXL } from './__mock__/AccuWeatherMock';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const AccuWeather: FC<AccuWeatherProps> = pageProps => {
  const isServer = !(typeof window != 'undefined' && window.document);
  const { preProcessorResponse, sessionID, requestId, lang } = useContext(PageParamsContext);
  const { cna = false, sameTab = false } = preProcessorResponse || {};
  const [forecast, setForecast] = useState(Array<forecastType>);
  let { data, loading } = useQuery(accuWeather, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: preProcessorResponse?.marsha ?? '',
    },
    context: {
      headers: {
        //Update the variables of AriesReservation for ppv5
        'accept-language': lang ?? 'en-US',
        'x-b3-traceId': sessionID ?? 'fallback-token',
        'x-b3-spanId': requestId !== '' ? requestId : `${Date.now()}`,
        'correlation-id': sessionID ?? 'fallback-token',
      },
    },
    skip: pageProps.isAuthorMode,
  });

  if (pageProps?.isAuthorMode) {
    loading = false;
    data = AccuWeatherMockUXL;
  }

  useEffect(() => {
    if (pageProps.selectPositioning === 'nearbyAccuweather' && data && data?.property?.weather?.tenDay) {
      const fore: Array<forecastType> = [];
      const presentDate = new Date().getTime();
      const weatherDate = new Date(data?.property?.weather?.tenDay[0].date).getTime();
      // Verify if the current date on the system matches the date on the UXL weather data at index 0
      // Difference between present date and UXL weather date at index 0
      const dateDiff = Math.ceil((presentDate - weatherDate) / (1000 * 3600 * 24));

      data?.property?.weather?.tenDay?.forEach?.(
        (
          item: {
            date: string | number | Date;
            day: { weatherIcon: number };
            temperature: { maximum: { value: number; unit: string } };
            mobileLink: string;
            link: string;
          },
          index: number
        ) => {
          // Display data for the next five days, including today
          if (index >= dateDiff && index < dateDiff + 5) {
            fore.push({
              date: new Date(item?.date),
              weatherIcon: item?.day?.weatherIcon,
              temperature: {
                value: Math.trunc(item?.temperature?.maximum?.value),
                unit: item?.temperature?.maximum?.unit,
              },
              mobileLink: item?.mobileLink,
              link: item?.link,
            });
          }
        }
      );
      setForecast(fore);
    }
  }, [data, loading, pageProps.selectPositioning]);

  return (
    <StyledAccuWeather data-testid="accuWeather" data-component-name="o-ppv5-accuWeather">
      {pageProps?.selectPositioning === 'headerAccuweather' &&
        !loading &&
        data?.property?.weather?.hourly.length > 0 && (
          <div className="accu-weather__container d-flex justify-content-end">
            <a
              href={`${data?.property?.weather?.hourly[0]?.link}&partner=web_marriott_adc`}
              target={sameTab || cna ? '_self' : '_blank'}
              className="accu-weather__container__wrapper"
              rel="noreferrer"
            >
              <div className="d-flex accu-weather__container__wrapper-cell">
                <div className="icon-cell">
                  {weatherIcons?.map(
                    icon =>
                      data?.property?.weather?.hourly[0]?.weatherIcon === icon.iconKey && (
                        <span className={icon.iconClass}></span>
                      )
                  )}
                </div>
                <div>
                  <span className="temperature-cell t-font-m">
                    {Math.trunc(data?.property?.weather?.hourly[0]?.temperature?.value)}
                  </span>
                  <span className="unit-cell">°{data?.property?.weather?.hourly[0]?.temperature?.unit}</span>
                </div>
              </div>
            </a>
            <div className="accu-weather__container__logo pl-2 pl-xl-3 d-flex">
              <a
                href={`${data?.property?.weather?.hourly[0]?.link}&partner=web_marriott_adc`}
                target={sameTab || cna ? '_self' : '_blank'}
                rel="noreferrer"
                className="d-flex align-items-center"
                data-testid="accu-weather-header-link"
              >
                <span>
                  <img
                    src={pageProps.accuWeatherImage}
                    alt={pageProps.imageAltText}
                    data-testid="accu-weather-logo"
                    className="accu-weather__container__logo_img d-none d-xl-flex"
                  ></img>
                </span>
                <span className="icon-forward-arrow t-accent-color"></span>
              </a>
            </div>
          </div>
        )}
      {pageProps.selectPositioning === 'nearbyAccuweather' && !loading && forecast.length > 0 && (
        <div className="d-flex">
          <div className="container">
            <div className="accu-weather-forecast__container d-flex justify-content-center">
              {!loading && (
                <>
                  <div className="accu-weather-forecast__container__content">
                    <span className="t-font-xs" data-testid="near-by-accu-weather">
                      {pageProps.weatherForecast}
                    </span>
                  </div>
                  <li className="d-flex">
                    {forecast?.map((weather, index) => {
                      return (
                        <a
                          href={`${weather.link}&partner=web_marriott_adc`}
                          target={sameTab || cna ? '_self' : '_blank'}
                          className="accu-weather-forecast__container__wrapper"
                          rel="noreferrer"
                          key={`forecast-day-${index}`}
                          data-testid={`forecast-day-link-${index}`}
                        >
                          <div className="d-flex accu-weather__container__wrapper-cell">
                            <div className="d-flex forecast-day-cell">
                              <span className="forecast-day-cell-day t-font-xs" data-testid={`forecast-day-${index}`}>
                                {
                                  pageProps.daysOfWeek[
                                    new Date(weather.date).getDay() > 0 ? new Date(weather.date).getDay() - 1 : 6
                                  ]
                                }
                              </span>
                            </div>
                            <div className="icon-cell">
                              {weatherIcons?.map((icon, i) => {
                                if (weather?.weatherIcon === icon.iconKey) {
                                  return (
                                    <span
                                      className={icon.iconClass}
                                      data-testid={`forecast-day-${index}-icon-${i}`}
                                    ></span>
                                  );
                                }
                                return <span></span>;
                              })}
                            </div>
                            <div>
                              <span className="forecast-temperature-cell t-font-m">{weather?.temperature?.value}</span>
                              <span className="forecast-unit-cell">°{weather?.temperature?.unit}</span>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </li>
                  <div className="accu-weather-forecast__container__logo pl-2 pl-xl-3 d-flex">
                    <a
                      href={`${data?.property?.weather?.tenDay[0]?.link}&partner=web_marriott_adc`}
                      target={sameTab || cna ? '_self' : '_blank'}
                      rel="noreferrer"
                      className="d-flex align-items-center"
                    >
                      <span>
                        <img
                          src={pageProps.accuWeatherImage}
                          alt={pageProps.imageAltText}
                          className="accu-weather__container__logo_img"
                        ></img>
                      </span>
                      <span className="icon-forward-arrow t-accent-color"></span>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </StyledAccuWeather>
  );
};

export const AccuWeatherConfig = {
  emptyLabel: 'AccuWeather',
  isEmpty: (_props: MappedComponentProperties) => false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/accuWeather`,
} as Config<MappedComponentProperties>;

export const AccuWeatherEditable = (props: AccuWeatherProps) => (
  <EditableComponent config={AccuWeatherConfig} {...props}>
    <AccuWeather {...props} />
  </EditableComponent>
);
