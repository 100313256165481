export const NEXT_PUBLIC_ENV_KEYS = [
  'NEXT_PUBLIC_UAT_AUTH_TOKEN',
  'APOLLOGRAPHQL_ACTIVE_IN_AEM_PREVIEW',
  'SESSION_GET_CALL_URL_CLIENT',
  'NEXT_PUBLIC_SUBMIT_SEARCH_URL',
  'NEXT_PUBLIC_AVAILABILITY_SEARCH_URL',
];

export const LOCALE_SUBDIRECTORY_MAPPING: Record<string, string> = {
  'it-IT': '/it',
  'ru-RU': '/ru',
  'en-GB': '/en-gb',
  'ko-KR': '/ko',
  'en-US': '/en',
  'pt-BR': '/pt-br',
  'zh-CN': '/cn',
  'fr-FR': '/fr',
  'ja-JP': '/ja',
  'de-DE': '/de',
  'pt-PT': '/pt',
  'es-ES': '/es',
};

export const BREAKPOINT_TABLET = 767;
export const BREAKPOINT_DESKTOP = 991;
export const RECENT_SEARCH_LIST_LIMIT = 5;
export const croppingRatio = 6;
export const outputQuality = 90;
export const outputInterpolation = 'progressive-bilinear';
export const SURCHARGE_ORDINANCE_CODE = 'surchargeOrdinanceCostDescription';
export const MANDATORYFEE_TITLE = 'Surcharge For Ordinance Cost';
export const FEEDESCRIPTION1 = 'Includes';
export const FEEDESCRIPTION2 = 'Surcharge for Ordinance Cost';
export const VIEW_RATE_LABEL = 'View Rates';
export const INTERNAL_CTA_TYPE = 'Internal';
export const EXTERNAL_CTA_TYPE = 'External';
export const BREADCRUMB_ALIGNMENT = 'horizontal-align';
export const MILES_TO_METERS = 1609.344;