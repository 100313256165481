/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge, Button, Text, Types } from '@marriott/mi-ui-library';
import { BadgeVariation, ExploreDestinationsCardProps, ExploreDestinationsProps } from './ExploreDestinations.types';
import { StyledExploreDestinations } from './ExploreDestinations.styles';
import { INTERNAL_CTA_TYPE } from '@marriott/mi-leisure-components/constants';
import { offersDataMock } from './__mock__/ExploreDestinationsMock.mock';

export const ExploreDestinationsWrapper: React.FC<ExploreDestinationsProps> = props => {
  const { mbeData, model, isAuthorMode } = props;
  const destinationDetails = !isAuthorMode ? mbeData?.destinations : offersDataMock?.destinations;
  const cardCount = destinationDetails?.length ?? 0;
  return (
    <StyledExploreDestinations>
      <div className="explore-destination-header mb-4">
        <Text
          element={Types.tags.paragraph}
          copyText={model?.title ?? `Explore Destinations`}
          fontSize={Types.size.extraLarge}
        />
      </div>
      <div className="destination-wrapper">
        {cardCount > 0 &&
          destinationDetails?.map((card: ExploreDestinationsCardProps) => (
            <div className="destination-card">
              <Button
                isLink={true}
                href={card?.url}
                className="destination-content"
                custom_click_track_value={`${model?.id}|${card?.id}-${card?.title}|${INTERNAL_CTA_TYPE}`}
              >
                <div className="destination-name">{card?.title}</div>
                <div className="destination-badge">
                  <Badge badgeVariation={BadgeVariation.Inline} badgeText={card?.count} />
                </div>
                <div className="cta-icon">
                  <span className="icon-arrow-right"></span>
                </div>
              </Button>
            </div>
          ))}
      </div>
    </StyledExploreDestinations>
  );
};
export default ExploreDestinationsWrapper;
