// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-next-link {
    cursor: pointer;
    font-size: ${toRem(12)} !important;
    font-weight: 500 !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .pagination-prev-link {
    cursor: pointer;
    font-size: ${toRem(12)} !important;
    font-weight: 500 !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .page-arrow-container {
    display: flex;
    align-items: center;
    cursor: default;
  }

  .pagination {
    list-style-type: none;
    padding-left: ${toRem(0)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${toRem(14)} !important;
    font-weight: 700;
    column-gap: ${toRem(8)};
    .pagination-item {
      margin: 0 ${toRem(1)};

      border-radius: 50%;
      cursor: pointer;
      border: ${toRem(1)} solid transparent;
      height: ${toRem(24)};
      width: ${toRem(24)};
      line-height: ${toRem(16)};
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border: ${toRem(1)} solid;
      }
      &.active {
        background-color: var(--t-form-label-color);
        color: var(--t-brand-bg-color);
        &:hover {
          cursor: default;
        }
      }
    }
    .pagination-dots {
      width: ${toRem(14)} !important;
      margin: 0;
      padding: 0;
      &:hover {
        border: none;
        cursor: default;
        background-color: none;
      }
    }
    .pagination-arrow {
      font-size: ${toRem(14)};
      width: auto;
      padding: ${toRem(2)} 0;
      &.disabled {
        pointer-events: none;
        opacity: 0.6;
        color: ${baseVariables.color['close-silver']};
      }

      .pagination-arrow-next {
        width: auto;
        height: ${toRem(24)};
        padding: ${toRem(2)} 0;
        &.disabled {
          pointer-events: none;
          opacity: 0.6;
          color: ${baseVariables.color['close-silver']};
        }
      }

      .icon-arrow-right,
      .icon-arrow-left {
        &:before {
          //width: ${toRem(24)};
          //height: ${toRem(24)};
          cursor: pointer;
        }
      }
    }

    .pagination-arrow:not(.disabled) {
      cursor: pointer;
    }
  }
`;
